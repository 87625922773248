import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import {
  Card,
  Button,
  ButtonGroup,
  FormLayout,
  Select,
  TextField,
  Tooltip,
  Banner,
  Tag,
  DataTable,
  Text,
  Box,
} from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import _ from "lodash";
import { parseServerError } from "utils/errors";
import Modal from "components/Modal";
import {
  createMerch,
  deleteMerch,
  downloadMerchFile,
  editMerch,
  fetchMerch,
} from "../../redux/features/merch-settings/merchSettingsSlice";
import {
  fetchMerchSettingsMassages,
  fetchNewMessagesCount,
} from "../../redux/features/messenger/messengerSlice";
import { resetSystemMessage } from "../../redux/features/messenger/messengerSlice";
import formatRow from "utils/formatRow";
import paginate from "utils/paginate";
import Pagination from "components/Pagination/Pagination";

import DeleteIMG from "img/delete.svg";
import EditIMG from "img/settings.svg";
import InfoTooltip from "components/InfoTooltip/InfoTooltip";
import { createCustomDispatch } from "helpers/customDispatch";

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class MerchSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      merchId: "",
      countryEst: this.props.countries ? this.props.countries[0]?.code : null,
      merchName: "",
      merchEUVat: "",
      merchOSS: "",
      merchUKVat: "",
      countryDep: "",
      addMerchError: null,

      actionDialogs: {
        deleteMerch: { open: false },
        editMerch: { open: false },
      },
      sortingColumns: [],
      pagination: {
        page: 1,
        perPage: 15,
      },
    };
  }

  onSelectPage = (page) => {
    const rows = this.props.merchants;

    const pages = Math.ceil(rows.length / this.state.pagination.perPage);

    this.setState({
      pagination: {
        ...this.state.pagination,
        page: Math.min(Math.max(page, 1), pages),
      },
    });
  };

  getAllCountriesForSelect = () => {
    return _.map(this.props.countries, (country) => ({
      label: country.name_no_article,
      value: country.code,
    }));
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleFileSelect = () => {
    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = () => {
        const file = this.fileUploadInput.files[0];
        this.setState({
          selectedFile: file,
          selectedFileName: file && file.name,
        });
      };
    }
  };

  doUploadFile = () => {
    const data = new FormData();
    data.append("file", this.state.selectedFile, this.state.selectedFileName);
    this.props
      .downloadMerchFile(data)
      .then(() => {
        this.doDeleteFile();
        this.props.fetchMerchSettingsMassages();
        this.props.fetchNewMessagesCount();
      })
      .catch(_.noop);
  };

  doDeleteFile = () => {
    this.setState({
      selectedFile: null,
      selectedFileName: false,
    });
  };

  handleChange = (value, id) => {
    this.setState({
      [id]: value,
    });
  };

  updateCellData = (value, name) => {
    const { actionDialogs } = this.state;
    this.setState({
      actionDialogs: {
        ...actionDialogs,
        cellData: { ...actionDialogs.cellData, [name]: value },
      },
    });
  };

  toggleAddMerch = (status = true) => {
    this.setState({
      isOpenAddMerch: status,
      merchId: "",
      countryEst: this.props.countries ? this.props.countries[0].code : null,
      merchName: "",
      merchEUVat: "",
      merchOSS: "",
      merchUKVat: "",
      countryDep: "",
    });
  };

  addMerch = () => {
    const {
      merchId,
      countryEst,
      merchName,
      merchEUVat,
      merchOSS,
      merchUKVat,
      countryDep,
    } = this.state;

    const params = {
      merchant_id: merchId,
      country_of_establishment: countryEst,
      merchant_name: merchName,
      merchant_eu_vat_number: merchEUVat,
      merchant_eu_oss_number: merchOSS,
      merchant_uk_vat_number: merchUKVat,
      merchant_default_departure_country: countryDep,
    };

    this.props
      .createMerch(params)
      .then(() => {
        this.props.fetchMerch();
        this.toggleAddMerch(false);
      })
      .catch((err) =>
        this.setState({
          addMerchError: err.error,
        })
      );
  };

  deleteMerch = (id) => {
    this.props
      .deleteMerch(id)
      .then(() => {
        this.props.fetchMerch().then(() => {
          this.handleActionDialogsClose("deleteMerch");
        });
      })
      .catch(_.noop);
  };

  editMerch = (data) => {
    const params = {
      id: data.id,
      merchant_id: data.merchant_id,
      merchant_name: data.merchant_name,
      country_of_establishment: data.country_of_establishment,
      merchant_eu_vat_number: data.merchant_eu_vat_number,
      merchant_eu_oss_number: data.merchant_eu_oss_number,
      merchant_uk_vat_number: data.merchant_uk_vat_number,
      merchant_default_departure_country:
        data.merchant_default_departure_country,
    };

    this.props
      .editMerch(params)
      .then(() => {
        this.props.fetchMerch().then(() => {
          this.handleActionDialogsClose("editMerch");
          this.setState({
            editError: false,
          });
        });
      })
      .catch((err) => {
        this.setState({
          editError: parseServerError(err),
        });
      });
  };

  renderDeleteMerchDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;
    const data = actionDialogs.cellData;

    const onClose = () => {
      this.handleActionDialogsClose("deleteMerch");
    };

    return !_.isEmpty(data) ? (
      <Modal
        title={t("vatReg.delete")}
        onClose={onClose}
        iconType={"danger"}
        visible={actionDialogs.deleteMerch.open}
        description={`Do you want delete merchant id ${data.merchant_id}?`}
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("vatReg.cancel")}
            </Button>
            <Button
              primary
              onClick={() => this.deleteMerch(data.id)}
              size='large'
              destructive
            >
              {t("vatReg.delete")}
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  }

  renderEditMerchDialog = () => {
    const { actionDialogs, editError } = this.state;
    const { t } = this.props;
    const data = actionDialogs.cellData;

    const onClose = () => {
      this.handleActionDialogsClose("editMerch");
      this.setState({
        editError: false,
      });
    };

    return (
      data && (
        <Modal
          title={t("vatReg.edit")}
          onClose={onClose}
          visible={actionDialogs.editMerch.open}
          content={
            <>
              {editError && (
                <div>
                  <Banner title={"Error"} status='critical' />
                  <br />
                </div>
              )}
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    id='merchant_id'
                    label={"Merchant id:"}
                    type='text'
                    value={data.merchant_id}
                    onChange={(value, id) => this.updateCellData(value, id)}
                  />
                  <Select
                    id='country_of_establishment'
                    label={"Country of establishment:"}
                    options={[
                      {
                        label: "",
                        value: "",
                      },
                      ...this.getAllCountriesForSelect(),
                    ]}
                    value={data.country_of_establishment}
                    onChange={(value, id) => this.updateCellData(value, id)}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    id='merchant_name'
                    label={"Merchant name:"}
                    type='text'
                    value={data.merchant_name}
                    onChange={(value, id) => this.updateCellData(value, id)}
                  />
                  <TextField
                    id='merchant_eu_vat_number'
                    label={"Merchant EU VAT number:"}
                    type='text'
                    value={data.merchant_eu_vat_number}
                    onChange={(value, id) => this.updateCellData(value, id)}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    id='merchant_eu_oss_number'
                    label={"Merchant OSS number:"}
                    type='text'
                    value={data.merchant_eu_oss_number}
                    onChange={(value, id) => this.updateCellData(value, id)}
                  />
                  <TextField
                    id='merchant_uk_vat_number'
                    label={"Merchant UK VAT number:"}
                    type='text'
                    value={data.merchant_uk_vat_number}
                    onChange={(value, id) => this.updateCellData(value, id)}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    id='merchant_default_departure_country'
                    label={"Merchant default Departure Country:"}
                    options={this.getAllCountriesForSelect()}
                    value={data.merchant_default_departure_country}
                    onChange={(value, id) => this.updateCellData(value, id)}
                  />
                  <br />
                </FormLayout.Group>
              </FormLayout>
            </>
          }
          footer={
            <ButtonGroup fullWidth>
              <Button onClick={onClose} size='large'>
                {t("vatReg.cancel")}
              </Button>
              <Button primary onClick={() => this.editMerch(data)} size='large'>
                {t("vatReg.edit")}
              </Button>
            </ButtonGroup>
          }
        />
      )
    );
  };

  renderAddMerch() {
    const { creatingMerch, t } = this.props;
    const {
      merchId,
      countryEst,
      merchName,
      merchEUVat,
      merchOSS,
      merchUKVat,
      countryDep,
      addMerchError,
    } = this.state;

    return (
      <div>
        <br />
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              id='merchId'
              label={"Merchant id:"}
              type='text'
              value={merchId}
              onChange={(value, id) => this.handleChange(value, id)}
            />
            <Select
              id='countryEst'
              label={"Country of establishment:"}
              options={this.getAllCountriesForSelect()}
              value={countryEst}
              onChange={(value, id) => this.handleChange(value, id)}
            />
            <TextField
              id='merchName'
              label={"Merchant name:"}
              type='text'
              value={merchName}
              onChange={(value, id) => this.handleChange(value, id)}
            />
            <TextField
              id='merchEUVat'
              label={"Merchant EU VAT number:"}
              type='text'
              value={merchEUVat}
              onChange={(value, id) => this.handleChange(value, id)}
            />
          </FormLayout.Group>
          <FormLayout.Group condensed>
            <TextField
              id='merchOSS'
              label={"Merchant OSS number:"}
              type='text'
              value={merchOSS}
              onChange={(value, id) => this.handleChange(value, id)}
            />
            <TextField
              id='merchUKVat'
              label={"Merchant UK VAT number:"}
              type='text'
              value={merchUKVat}
              onChange={(value, id) => this.handleChange(value, id)}
            />
            <Select
              id='countryDep'
              label={"Merchant default Departure Country:"}
              options={[
                {
                  label: "",
                  value: "",
                },
                ...this.getAllCountriesForSelect(),
              ]}
              value={countryDep}
              onChange={(value, id) => this.handleChange(value, id)}
            />
            <br />
          </FormLayout.Group>
        </FormLayout>
        {addMerchError && (
          <>
            <br />
            <Banner status='critical'>{addMerchError}</Banner>
          </>
        )}
        <br />
        <ButtonGroup>
          <Button
            primary
            disabled={!merchId || !merchName || !countryEst}
            loading={creatingMerch}
            onClick={this.addMerch}
          >
            Add new merchant
          </Button>
          <Button onClick={() => this.toggleAddMerch(false)}>
            {t("vatReg.cancel")}
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  renderMerchants() {
    const { user, t } = this.props;
    const { pagination } = this.state;

    const rows = this.props.merchants;
    
    let columns = [
      {
        property: "merchant_id",
        header: {
          label: "Merchant id",
        },
      },
      {
        property: "country_of_establishment_name",
        header: {
          label: "Country of establishment",
        },
      },
      {
        property: "merchant_name",
        header: {
          label: "Merchant name",
        },
      },
      {
        property: "merchant_eu_vat_number",
        header: {
          label: "Merchant EU VAT number",
        },
      },
      {
        property: "merchant_eu_oss_number",
        header: {
          label: "Merchant OSS number",
        },
      },
      {
        property: "merchant_uk_vat_number",
        header: {
          label: "Merchant UK VAT Number",
        },
      },
      {
        property: "merchant_default_departure_country_name",
        header: {
          label: "Merchant default Departure Country",
        },
      },
    ];

    if (user.is_manager) {
      columns = [
        ...columns,
        {
          property: "actions",
          header: {
            label: t("vatReg.actions"),
            transforms: [],
          },
          cell: {
            formatters: [
              (value, { rowData }) => (
                <span style={{ display: "flex" }}>
                  <Tooltip content={t("vatReg.delete")}>
                    <Button
                      plain
                      onClick={() =>
                        this.handleActionDialogsOpen("deleteMerch", rowData)
                      }
                    >
                      <img src={DeleteIMG} alt='delete' />
                    </Button>
                  </Tooltip>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <Tooltip content={t("vatReg.edit")}>
                    <Button
                      plain
                      onClick={() =>
                        this.handleActionDialogsOpen("editMerch", rowData)
                      }
                    >
                      <img src={EditIMG} alt='edit' />
                    </Button>
                  </Tooltip>
                </span>
              ),
            ],
          },
        },
      ];
    }

    const sortedRows = formatRow(rows, columns);

    const paginatedRows = paginate(pagination)(sortedRows);

    return (
      <div>
        {!_.isEmpty(rows) && (
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={paginatedRows.rows}
            hideScrollIndicator
          />
        )}
        {!_.isEmpty(rows) && rows.length > pagination.perPage && (
          <div style={{ margin: "0 1rem" }}>
            <Pagination
              total={this.props.merchants.length}
              current={pagination.page}
              pageSize={pagination.perPage}
              onChange={(current) => this.onSelectPage(current)}
            />
          </div>
        )}
      </div>
    );
  }

  renderNumberOfMerchantsAvailable = () => {
    const { currentPlan, merchants } = this.props;
    if (!currentPlan) return null;

    const maxMerchants =
      currentPlan.subscriptions_omp.tariff_plan.number_of_merchants;
    const merchantsInUse = merchants.length;
    const isExceeded = merchantsInUse > maxMerchants;

    if (!isExceeded) return null;

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="2"
      >
        <Text color='subdued'>Number of available merchants:</Text>
        <Text color='critical'>{`${merchantsInUse}/${maxMerchants}`}</Text>
      </Box>
    );
  };

  render() {
    const { downloadingMerch, merchSettingsMessages, t } = this.props;
    const { selectedFileName } = this.state;

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: 20}}>
        {merchSettingsMessages.map((message) => (
          <Banner
            key={message.id}
            title={
              (message.status === "ready" && "Success") ||
              (message.status === "error" && "Error") ||
              "Warning"
            }
            status={
              (message.status === "ready" && "success") ||
              (message.status === "error" && "critical") ||
              "warning"
            }
            onDismiss={() => {
              this.props
                .resetSystemMessage({
                  modelsName: "merchant_settings",
                  id: message.id,
                })
                .then(() => this.props.fetchMerchSettingsMassages())
                .then(() => this.props.fetchNewMessagesCount())
                .catch(_.noop);
            }}
          >
            <span>File: "{message.name}"</span>
            <span>{message.text}</span>
          </Banner>
        ))}
        {this.renderNumberOfMerchantsAvailable()}
        <Card>
          {this.renderMerchants()}
          <Card.Section>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              {!this.state.isOpenAddMerch && (
                <div style={{width: '300px', marginBottom: '10px'}}>
                  <Button
                    loading={this.props.adding}
                    primary
                    onClick={() => this.toggleAddMerch(true)}
                    >
                    Add new merchant
                  </Button>
                </div>
              )}
              <ButtonGroup>
                <Button
                  onClick={
                    selectedFileName ? this.doUploadFile : this.handleFileSelect
                  }
                  primary={selectedFileName}
                  loading={downloadingMerch}
                  disabled={this.state.isOpenAddMerch}
                >
                  {selectedFileName
                    ? "Upload merchants"
                    : t("vatReg.chooseFile")}
                </Button>

                <InfoTooltip
                  content={"You can upload a dataset for Merchant file here"}
                />

                <input
                  type='file'
                  accept='.csv'
                  style={{ display: "none" }}
                  onClick={(evt) => (evt.target.value = null)}
                  ref={(input) => (this.fileUploadInput = input)}
                />

                {selectedFileName && (
                  <Tag onRemove={this.doDeleteFile}>{selectedFileName}</Tag>
                )}
              </ButtonGroup>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                <h6 style={{display: 'flex', alignItems: 'center'}}>choose <p style={{fontWeight: 'bold', fontSize: 18}}>*.CSV</p> file toupload</h6>
                <a
                  className='Polaris-Link'
                  href='/files/merchants.csv'
                  download
                  style={{marginLeft: 10}}
                >
                  download template
                </a>
              </div>
            </div>
            {this.state.isOpenAddMerch && this.renderAddMerch()}
          </Card.Section>
          {this.renderDeleteMerchDialog()}
          {this.renderEditMerchDialog()}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.other.countries,
  user: state.user.user,
  merchants: state.merchSettings.merchants,
  creatingMerch: state.merchSettings.creatingMerch,
  downloadingMerch: state.merchSettings.downloadingMerch,
  merchSettingsMessages: state.messenger.merchSettingsMessages,
  defaultLanguage: state.user.defaultLanguage,
  currentPlan: state.subscription.currentPlan,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchMerch: () => dispatch(fetchMerch()),
    createMerch: (params) => dispatch(createMerch(params)),
    deleteMerch: (id) => dispatch(deleteMerch(id)),
    editMerch: (data) => dispatch(editMerch(data)),
    downloadMerchFile: (file) => dispatch(downloadMerchFile(file)),
    fetchNewMessagesCount: () => dispatch(fetchNewMessagesCount()),
    fetchMerchSettingsMassages: () => dispatch(fetchMerchSettingsMassages()),
    resetSystemMessage: (params) => dispatch(resetSystemMessage(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MerchSettings))
);
