import {
  Button,
  Checkbox,
  Text,
} from "@shopify/polaris";
import _ from "lodash";
import * as PropTypes from 'prop-types'
import Select from "react-select";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import TopMenuSelectedProjects from './TopMenuSelectedProjects'
import './topbar.css'

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class TopMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: "",
      filterActive: false,
      filterTrial: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    if (!prevProps.selected && selected) {
      this.updateSelected();
    }
  }

  updateSelected = () => {
    const { selected } = this.props;
    this.setState({
      selected,
    });
  };

  handleSelectChange = (value) => {
    this.setState({ selected: value });
  };

  render() {
    const { title, loadingButtonClick } = this.props;
    const { selected, filterActive, filterTrial } = this.state;
    let options = [];

    if (this.props.options) {
      let filteredOptions = [...this.props.options];
      if (filterActive && filterTrial) {
        filteredOptions = _.filter(
          filteredOptions,
          (item) => item.status === "active" || item.status === "trial"
        );
      } else if (filterActive) {
        filteredOptions = _.filter(
          filteredOptions,
          (item) => item.status === "active"
        );
      } else if (filterTrial) {
        filteredOptions = _.filter(
          filteredOptions,
          (item) => item.status === "trial"
        );
      } else {
        filteredOptions = filteredOptions;
      }
      options = filteredOptions.map((company) => {
        return {
          label: company.name,
          value: company.id,
        };
      });
    }

    const menuOptions = [
      {
        label: "",
        value: "",
      },
      ...options,
    ];

    return (
      <div
        style={{
          padding: "10px",
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #dfe3e8",
        }}
      >
        <div className='Polaris-Page' style={{width: '100%', maxWidth: '100%'}}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <p >
              <Text
                as='h6'
                variant='headingMd'
                fontWeight='bold'
              >
                {title}
              </Text >
            </p >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                label='Active'
                checked={filterActive}
                onChange={() =>
                  this.setState((prevState) => ({
                    filterActive: !prevState.filterActive,
                    selected: "",
                  }))
                }
              />
              <Checkbox
                label='Trial'
                checked={filterTrial}
                onChange={() =>
                  this.setState((prevState) => ({
                    filterTrial: !prevState.filterTrial,
                    selected: "",
                  }))
                }
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <div style={{minWidth: "150px"}}>
                <Select
                  id='companyId'
                  value={
                    menuOptions?.find(({value}) => value === selected) || ""
                  }
                  onChange={({value}) => {
                    this.setState({selected: value});
                  }}
                  options={menuOptions}
                  styles={{
                    menu: (provided) => ({...provided, zIndex: 9999}),
                  }}
                />
              </div >
              <Button
                primary
                disabled={!selected}
                onClick={() => this.props.changeCompanyName(selected)}
                loading={loadingButtonClick}
              >
                Activate
              </Button >
            </div>
            
            <TopMenuSelectedProjects/>
          </div >
        </div >
      </div >
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TopMenu))
);
