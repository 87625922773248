import { Checkbox, Select, TextField } from '@shopify/polaris'
import Datepicker from 'components/Datepicker'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SearchingCountry from '../../../../../../components/SearchingCountry/SearchingCountry'
import { fetchCountries, fetchCurrency } from '../../../../../../redux/features/other/otherSlice'

const FormsForCreateNewTransactionGoods = ({
	data,
	dispatch,
	setFormDataHandler,
	dateRange
}) => {
	const {countries, currency} = useSelector((state) => state.other)
	const getCurrentDateTime = () => {
		const now = new Date()
		const offset = now.getTimezoneOffset() * 60000
		const localDate = new Date(now.getTime() - offset)
		return localDate.toISOString()
	}
	// Required Fields States
	const [transactionSum, setTransactionSum] = useState('')
	const [transactionDatetime, setTransactionDatetime] = useState(getCurrentDateTime())
	const [departureCountry, setDepartureCountry] = useState('')
	const [arrivalCountry, setArrivalCountry] = useState('')
	const [currencyValue, setCurrencyValue] = useState('EUR')
	const [transactionType, setTransactionType] = useState('b2c_from_premises')
	
	// Additional Fields States
	const [transactionId, setTransactionId] = useState('')
	const [vatInvoiceNumber, setVatInvoiceNumber] = useState('')
	const [invoiceDate, setInvoiceDate] = useState('')
	const [orderId, setOrderId] = useState('')
	const [parentTransactionId, setParentTransactionId] = useState('')
	const [shipmentFee, setShipmentFee] = useState('')
	const [departureZip, setDepartureZip] = useState('')
	const [departureState, setDepartureState] = useState('')
	const [departureCity, setDepartureCity] = useState('')
	const [departureAddressLine, setDepartureAddressLine] = useState('')
	const [arrivalZip, setArrivalZip] = useState('')
	const [arrivalState, setArrivalState] = useState('')
	const [arrivalCity, setArrivalCity] = useState('')
	const [arrivalAddressLine, setArrivalAddressLine] = useState('')
	const [paymentDatetime, setPaymentDatetime] = useState('')
	const [goodCode, setGoodCode] = useState('')
	const [itemDescription, setItemDescription] = useState('')
	const [quantity, setQuantity] = useState('')
	const [buyerName, setBuyerName] = useState('')
	const [buyerId, setBuyerId] = useState('')
	const [deemed, setDeemed] = useState(false)
	const [buyerVatNumber, setBuyerVatNumber] = useState('')
	
	// ------Functionals--------
	
	useEffect(() => {
		const formData = {}
		
		if (transactionDatetime) formData.transaction_datetime = transactionDatetime
		if (transactionSum) formData.transaction_sum = parseFloat(transactionSum)
		if (currencyValue) formData.currency = currencyValue
		if (departureCountry) formData.departure_country = departureCountry
		if (arrivalCountry || arrivalCountry?.code) formData.arrival_country = arrivalCountry?.code || arrivalCountry
		if (transactionType) formData.transaction_type = transactionType
		if (buyerVatNumber) formData.buyer_vat_number = buyerVatNumber
		if (vatInvoiceNumber) formData.inv_number = vatInvoiceNumber
		if (transactionId) formData.transaction_id = transactionId
		if (orderId) formData.order_id = orderId
		if (parentTransactionId) formData.parent_transaction_id = parentTransactionId
		if (shipmentFee) formData.shipment_fee = parseFloat(shipmentFee)
		if (departureZip) formData.departure_zip = departureZip
		if (departureState) formData.departure_state = departureState
		if (departureCity) formData.departure_city = departureCity
		if (departureAddressLine) formData.departure_address_line = departureAddressLine
		if (arrivalZip) formData.arrival_zip = arrivalZip
		if (arrivalState) formData.arrival_state = arrivalState
		if (arrivalCity) formData.arrival_city = arrivalCity
		if (arrivalAddressLine) formData.arrival_address_line = arrivalAddressLine
		if (paymentDatetime) formData.payment_date_time = paymentDatetime
		if (invoiceDate) formData.inv_date = invoiceDate
		if (goodCode) formData.good_code = goodCode
		if (itemDescription) formData.item_description = itemDescription
		if (quantity) formData.quantity = parseInt(quantity, 10)
		if (buyerName) formData.buyer_name = buyerName
		if (buyerId) formData.buyer_id = buyerId
		if (deemed) formData.deemed = deemed
		
		setFormDataHandler(formData)
	}, [
		transactionSum,
		transactionDatetime,
		departureCountry,
		arrivalCountry,
		currencyValue,
		transactionType,
		buyerVatNumber,
		vatInvoiceNumber,
		transactionId,
		orderId,
		parentTransactionId,
		shipmentFee,
		departureZip,
		departureState,
		departureCity,
		departureAddressLine,
		arrivalZip,
		arrivalState,
		arrivalCity,
		arrivalAddressLine,
		paymentDatetime,
		invoiceDate,
		goodCode,
		itemDescription,
		quantity,
		buyerName,
		buyerId,
		deemed
	])
	
	const findArrivalCountry = () => {
		const res = countries.find((country) => country?.code === data?.customer_country_id)
		setArrivalCountry(res)
	}
	
	const fetchingDataForSelect = async () => {
		await dispatch(fetchCountries())
		await dispatch(fetchCurrency())
	}
	
	useEffect(() => {
		fetchingDataForSelect().catch(() => {})
	}, [])
	
	useEffect(() => {
		countries.length > 0 && findArrivalCountry()
	}, [countries])
	
	const handleDateChange = (selectedDate,
		line) => {
		if (selectedDate) {
			const isoString = selectedDate.toISOString()
			if (line === 'transaction') {
				setTransactionDatetime(isoString)
			} else if (line === 'payment') {
				setPaymentDatetime(isoString)
			}else if (line === 'invoiceDate') {
				setInvoiceDate(isoString)
			}
		}
	}
	
	const countryOptions = countries.map((country) => ({
		label: `${country.name} (${country.code})`,
		value: country.code,
		key: `${country.name}(${country.code})`,
	}))
	
	const currencyOptions = currency.map((item) => ({
		label: `${item}`,
		value: item,
		key: item,
	}))
	
	const handleTransactionSumChange = (value, line) => {
		const regex = /^-?\d*\.?\d{0,2}$/;
		if (regex.test(value)) {
			if (line === 'transactionSum') setTransactionSum(value);
			if (line === 'shipmentFee') setShipmentFee(value);
		}
	};
	
	return (
		<div className='transaction-create_form-wrapper'>
			<div className='transaction-create_form-wrapper_fields'>
				<h3 className='transaction-create_form-wrapper_fields-title'>
					Required Fields <span style={{color: 'red'}}>*</span >
				</h3 >
				<label >
				  Transaction Date <span style={{color: 'red'}}>*</span >
				</label >
				<Datepicker
					id='due_date'
					allowRange={false}
					value={transactionDatetime}
					onChange={newDate => handleDateChange(newDate, 'transaction')}
					autoComplete='off'
					placeholder='Select a date'
					label=''
					maxDateOverride={dateRange.date_end}
					minDateOverride={dateRange.date_start}
				/>
				
				<TextField
					label="Transaction amount (with tax)"
					placeholder="Enter Transaction amount (with tax)"
					value={transactionSum}
					onChange={(value) => handleTransactionSumChange(value, 'transactionSum')}
					type="text"
					inputMode="decimal"
					requiredIndicator
					className="form-input"
					autoComplete='off'
				/>
				
				<Select
					label='Currency'
					placeholder='Currency'
					options={currencyOptions}
					value={currencyValue}
					onChange={setCurrencyValue}
					className='form-select'
					requiredIndicator
				/>
				
				<SearchingCountry
					title={'Departure Country'}
					placeholder={`Enter Departure Country`}
					onChange={setDepartureCountry}
					dataCountriesList={countryOptions}
					changeCountry={departureCountry}
				/>
				<SearchingCountry
					title={'Arrival country'}
					placeholder={`Enter Arrival country`}
					onChange={setArrivalCountry}
					dataCountriesList={countryOptions}
					changeCountry={arrivalCountry?.name}
					isOpen={true}
				/>
			</div >
			
			{/*  ****************Second block************ */}
			<div className='transaction-create_form-wrapper_fields transaction-create_form-wrapper_fields-additional'>
				<h3 className='transaction-create_form-wrapper_fields-title'>Additional Fields</h3 >
				<TextField
					label='Buyer VAT number'
					placeholder='Enter Buyer VAT number'
					value={buyerVatNumber}
					onChange={(value) => setBuyerVatNumber(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='VAT invoice number'
					placeholder='Enter VAT invoice number'
					value={vatInvoiceNumber}
					onChange={(value) => setVatInvoiceNumber(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<Datepicker
					id='due_date3'
					allowRange={false}
					value={invoiceDate}
					onChange={newDate => handleDateChange(newDate, 'invoiceDate')}
					autoComplete='off'
					placeholder='Select a date'
					label='Invoice date'
					start={invoiceDate}
				/>
				<TextField
					label='Transaction id'
					placeholder='Enter Transaction id'
					value={transactionId}
					onChange={(value) => setTransactionId(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Order id'
					placeholder='Enter Order id'
					value={orderId}
					onChange={(value) => setOrderId(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Parent transaction id'
					placeholder='Enter Parent transaction id'
					value={parentTransactionId}
					onChange={(value) => setParentTransactionId(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label="Shipment fee"
					placeholder='Enter Shipment fee'
					value={shipmentFee}
					onChange={(value) => handleTransactionSumChange(value, 'shipmentFee')}
					type="text"
					inputMode="decimal"
					min="0"
					autoComplete='off'
					className="form-input"
				/>
				<TextField
					label='Departure ZIP'
					placeholder='Enter Departure ZIP'
					value={departureZip}
					onChange={(value) => setDepartureZip(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Departure State'
					placeholder='Enter Departure State'
					value={departureState}
					onChange={(value) => setDepartureState(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Departure City'
					placeholder='Enter Departure City'
					value={departureCity}
					onChange={(value) => setDepartureCity(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Departure address line'
					placeholder='Enter Departure address line'
					value={departureAddressLine}
					onChange={(value) => setDepartureAddressLine(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Arrival ZIP'
					placeholder='Enter Arrival ZIP'
					value={arrivalZip}
					onChange={(value) => setArrivalZip(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Arrival State'
					placeholder='Enter Arrival State'
					value={arrivalState}
					onChange={(value) => setArrivalState(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Arrival City'
					placeholder='Enter Arrival City'
					value={arrivalCity}
					onChange={(value) => setArrivalCity(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Arrival address line'
					placeholder='Enter Arrival address line'
					value={arrivalAddressLine}
					onChange={(value) => setArrivalAddressLine(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<Datepicker
					id='due_date2'
					allowRange={false}
					value={paymentDatetime}
					onChange={newDate => handleDateChange(newDate, 'payment')}
					autoComplete='off'
					placeholder='Select a date'
					label='Payment date time'
					start={paymentDatetime}
				/>
				<TextField
					label='Good code'
					placeholder='Enter Good code'
					value={goodCode}
					onChange={(value) => setGoodCode(value)}
					type='text'
					className='form-input'
					autoComplete='off'
					maxLength='10'
				/>
				<TextField
					label='Item description'
					placeholder='Enter Item description'
					value={itemDescription}
					onChange={(value) => setItemDescription(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Quantity'
					placeholder='Enter Quantity'
					value={quantity}
					onChange={(value) => setQuantity(parseInt(value))}
					type='number'
					className='form-input'
					min='0'
					step='1'
					autoComplete='off'
				/>
				<TextField
					label='Buyer name'
					placeholder='Enter Buyer name'
					value={buyerName}
					onChange={(value) => setBuyerName(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Buyer id'
					placeholder='Enter Buyer id'
					value={buyerId}
					onChange={(value) => setBuyerId(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<Checkbox
					label='Deemed'
					checked={deemed}
					onChange={(newChecked) => setDeemed(newChecked)}
					className='form-checkbox'
				/>
			</div >
	  </div >
	)
}

export default FormsForCreateNewTransactionGoods
