import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  FormLayout,
  Layout,
  Select,
  Text,
  TextField,
} from "@shopify/polaris";
import { useSelector } from "react-redux";
import { t } from "i18next";
import useAppDispatch from "hooks/useAppDispatch";
import SearchingCountry from 'components/SearchingCountry/SearchingCountry'
import { editBillingContact } from '../../../../redux/features/other/otherSlice'
import { fetchUser } from '../../../../redux/features/user/userSlice'

const getTypeTaxNumber = (type) => {
  switch (type) {
    case "vat_number":
      return "VAT Number";
    case "sales_tax_number":
      return "Sales Tax Number";
    case "tin":
      return "TIN";
    default:
      return "Tax Number";
  }
};

const BillingContactInfo = (props) => {
  const { toggleEditState } = props;
  const { user } = useSelector((state) => state.user);
  const {
    name,
    street,
    country,
    locality,
    postcode,
    type_tax_number,
    tax_number,
    phone,
    email,
  } = user.company.billing_contact;
  const address = `${postcode}, ${country}, ${locality}, ${street}`;

  return (
    <Card.Section>
      <Box
        display="flex"
        flexDirection="column"
        gap="5"
        width="100%"
      >
       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15,}}>
          <div>
            <Text variant='bodyLg'>{t("profileComp.companyName")}:</Text>
            <Text color='subdued'>{name}</Text>
          </div>

          <div style={{width: '30%'}}>
            <Text variant='bodyLg'>{t("profileComp.typeTaxNumber")}:</Text >
            <Text color='subdued'>{getTypeTaxNumber(type_tax_number)}</Text >
          </div >
        </div >

       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15,}}>
          <div>
            <Text variant='bodyLg'>{t("profileComp.email")}:</Text>
            <Text color='subdued'>{email}</Text>
          </div>

          <div style={{width: '30%'}}>
            <Text variant='bodyLg'>{t("profileComp.phone")}:</Text >
            <Text color='subdued'>{phone}</Text >
          </div >
        </div >

       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15,}}>
          <div>
            <Text variant='bodyLg'>{getTypeTaxNumber(type_tax_number)}:</Text>
            <Text color='subdued'>{tax_number}</Text>
          </div>
        </div>

       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15,}}>
          <div>
            <Text variant='bodyLg'>{t("profileComp.address")}:</Text>
            <Text color='subdued'>{address}</Text>
          </div>
        </div>

        <ButtonGroup>
          <Button primary onClick={() => toggleEditState(true)}>
            {t("profileComp.editBillingContact")}
          </Button>
        </ButtonGroup>
      </Box>
    </Card.Section>
  );
};

const BillingContactEdit = (props) => {
  const { toggleEditState } = props;
  const dispatch = useAppDispatch();
  const [editData, setEditData] = useState({});
  const { user } = useSelector((state) => state.user);
  const { editBillingContact: loading, countriesAll } = useSelector(
    (state) => state.other
  );
  const countriesOptions = countriesAll.map((country) => ({
    label: country.name_no_article,
    value: country.code,
  }));

  useEffect(() => {
    setEditData(user.company.billing_contact);
  }, [user]);

  const handleEditData = (value, field) => {
    setEditData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onSaveChanges = () => {
    const tehEditData = {
      ...editData,
      country: countriesOptions?.find(item => item.value === editData.country)?.label || editData.country,
      country_code: countriesOptions?.find(item => item.value === editData.country)?.value || editData.country_code,
    };

    dispatch(editBillingContact(tehEditData)).then(() => {
      toggleEditState(false);
      dispatch(fetchUser());
    });
  };

  return (
    <Card.Section>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            id='name'
            type='text'
            onChange={(value) => handleEditData(value, "name")}
            label={`${t("profileComp.companyName")}:`}
            value={editData.name}
          />
          <SearchingCountry
            title={`${t("profileComp.country")}:`}
            placeholder={`Enter ${t("profileComp.country")}`}
            onChange={(value) => handleEditData(value, "country")}
            dataCountriesList={countriesOptions}
            changeCountry={countriesOptions?.find(item => item.value === editData.country_code)?.label || ''}
            isOpen={true}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Select
            label={`${t("profileComp.typeTaxNumber")}:`}
            options={[
              "",
              { label: "VAT Number", value: "vat_number" },
              { label: "Sales Tax Number", value: "sales_tax_number" },
              { label: "TIN", value: "tin" },
            ]}
            onChange={(value) => handleEditData(value, "type_tax_number")}
            value={editData.type_tax_number}
          />
          <TextField
            id='tax_number'
            type='text'
            label={`${getTypeTaxNumber(editData.type_tax_number)}:`}
            onChange={(value) => handleEditData(value, "tax_number")}
            value={editData.tax_number}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            id='email'
            type='text'
            onChange={(value) => handleEditData(value, "email")}
            label={`${t("profileComp.email")}:`}
            value={editData.email}
          />
          <TextField
            id='phone'
            type='text'
            onChange={(value) => handleEditData(value, "phone")}
            label={`${t("profileComp.phone")}:`}
            value={editData.phone}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            id='postcode'
            type='text'
            maxLength='12'
            label={`${t("profileComp.postcode")}:`}
            onChange={(value) => handleEditData(value, "postcode")}
            value={editData.postcode}
          />
          <TextField
            id='locality'
            maxLength='30'
            type='text'
            label={`${t("profileComp.cityTown")}:`}
            onChange={(value) => handleEditData(value, "locality")}
            value={editData.locality}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            id='street'
            maxLength='30'
            type='text'
            label={`${t("profileComp.strAddress")}:`}
            onChange={(value) => handleEditData(value, "street")}
            value={editData.street}
          />
          <br />
        </FormLayout.Group>
      </FormLayout>

      <br />
      <ButtonGroup>
        <Button loading={loading} primary onClick={onSaveChanges}>
          {t("profileComp.saveChanges")}
        </Button>
        <Button onClick={() => toggleEditState(false)}>
          {t("profileComp.discard")}
        </Button>
      </ButtonGroup>
    </Card.Section>
  );
};

const BillingContact = () => {
  const [isEditState, toggleEditState] = useState(false);

  return (
    <Layout.AnnotatedSection
      title={
        <Text variant='headingLg' as='p'>
          {t("profileComp.billingContact")}:
        </Text>
      }
    >
      <Layout.Section>
        <Card>
          {isEditState ? (
            <BillingContactEdit toggleEditState={toggleEditState} />
          ) : (
            <BillingContactInfo toggleEditState={toggleEditState} />
          )}
        </Card>
      </Layout.Section>
    </Layout.AnnotatedSection>
  );
};

export default BillingContact;
