import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  deleteSalesTax,
  downloadSchedulesFile,
  fetchDeclarationsSalesTax, fetchSalesTaxPermits,
  getSalesTaxPreview,
  submitSalesTax,
} from "../../redux/features/declarations/declarationsSlice";
import {
  editTaxReturnStatus,
  sendTaxReturnFile,
} from "../../redux/features/manager/managerSlice";
import {
  Page,
  Layout,
  Card,
  Button,
  FormLayout,
  Select,
  Checkbox,
  Badge,
  Banner,
  Tooltip,
  Tag,
  ButtonGroup,
  Spinner,
  Text,
  DataTable,
  Box,
} from "@shopify/polaris";
import moment from "moment";
import _ from "lodash";
import Modal from "components/Modal/Modal";
import ReturnPreview from "../VATReturns/Previews/ReturnPreview";
import StripePayment from "../StripePayment/StripePayment";
import { preciseRound } from "utils/numbers";
import { formatDate, formatTime } from "utils/dates";
import formatRow from "utils/formatRow";
import paginate from "utils/paginate";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import Pagination from "components/Pagination/Pagination";

import deleteIMG from "img/delete.svg";
import importIMG from "img/import.svg";
import editIMG from "img/edit.svg";
import submitIMG from "img/vatSubmit.svg";
import eyeIMG from "img/eye.svg";
import { createCustomDispatch } from "helpers/customDispatch";

const defaultLimit = 25;

const quarterIndexFrom = 1;
const quarterIndexTo = moment().quarter();
const defaultDates = {
  from_date: moment()
    .quarter(quarterIndexFrom)
    .startOf("quarter")
    .format("YYYY-MM-DD"),
  to_date: moment()
    .quarter(quarterIndexTo)
    .endOf("quarter")
    .format("YYYY-MM-DD"),
};

class SalesTaxReturns extends Component {
  constructor(props) {
    super(props);

    const currYear = moment().year();
    const yearsItems = [];
    let tmpYear = currYear;
    do {
      yearsItems.push({ value: tmpYear, label: tmpYear });
      tmpYear -= 1;
    } while (tmpYear >= currYear - 10);

    /* get year and quarter from default dates */
    Object.assign(defaultDates, {
      from_quarter: moment(defaultDates.from_date).quarter(),
      to_quarter: moment(defaultDates.to_date).quarter(),
      from_year: moment(defaultDates.from_date).year(),
      to_year: moment(defaultDates.to_date).year(),
    });

    this.state = {
      sortingColumns: [],
      query: {
        ...defaultDates,
        state_code: undefined,
        limit: defaultLimit,
      },

      pagination: {
        page: 1,
        pages: 1,
        perPage: defaultLimit,
      },
      quarters: [
        { value: 1, label: this.props.t("vatReturns.q1") },
        { value: 2, label: this.props.t("vatReturns.q2") },
        { value: 3, label: this.props.t("vatReturns.q3") },
        { value: 4, label: this.props.t("vatReturns.q4") },
      ],
      years: yearsItems,

      actionDialogs: {
        preview: { open: false },
        submit: { open: false },
        status: { open: false },
        upload: { open: false },
        pay: { open: false },
        delete: { open: false },
        payAutosubmit: { open: false },
      },
      isChecked: false,
      action: "money",

      fileNameList: {
        tax_return_doc: [],
      },

      step: 1,
    };
  }

  componentDidMount() {
    this.props.fetchStates();
    this.props
      .fetchDeclarationsSalesTax({
        ...defaultDates,
        limit: defaultLimit,
      })
      .then(() => {
        const { declarationsSalesTax } = this.props;
        return this.setState({
          pages:
            declarationsSalesTax && declarationsSalesTax.count
              ? Math.ceil(declarationsSalesTax.count / defaultLimit) || 1
              : 1,
        });
      })
      .catch(_.noop);
  }

  componentWillReceiveProps(nextProps) {
    const pagination = this.state.pagination;
    pagination.pages =
      nextProps.declarationsSalesTax && nextProps.declarationsSalesTax.count
        ? Math.ceil(nextProps.declarationsSalesTax.count / defaultLimit) || 1
        : 1;
    this.setState({ pagination });
  }

  onSelectPage = (inputPage) => {
    const pages =
      Math.ceil(
        this.props.declarationsSalesTax.count / this.state.pagination.perPage
      ) || 1;
    const page = Math.min(Math.max(inputPage, 1), pages);

    const query = {
      offset: (page - 1) * this.state.pagination.perPage,
      limit: defaultLimit,
    };
    this.setState(
      {
        query: _.assign(this.state.query, query),
        pagination: {
          ...this.state.pagination,
          page: page,
          pages: pages,
        },
      },
      () => {
        this.props.fetchDeclarationsSalesTax(this.state.query);
      }
    );
  };

  statusArr = [
    {
      value: "new",
      label: this.props.t("vatReturns.statuses.new"),
    },
    {
      value: "pending",
      label: this.props.t("vatReturns.statuses.pending"),
    },
    {
      value: "submitted",
      label: this.props.t("vatReturns.statuses.submitted"),
    },
    {
      value: "declined",
      label: this.props.t("vatReturns.statuses.declined"),
    },
    {
      value: "draft",
      label: "draft",
    },
    {
      value: "ready",
      label: "ready",
    },
  ];

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleFileSelect = (docsType) => {
    const { fileNameList } = this.state;
    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = () => {
        const file = this.fileUploadInput.files;
        this.docsFiles[docsType][0] = file[0]; // add file
        fileNameList[docsType][0] = file[0].name; // add filename
        this.setState({ fileNameList });
      };
    }
  };

  downloadSchedules = (file, cellData) => {
    const fileName = `${file.name}_${cellData.state.name}_${cellData.period}_${cellData.period_type}`;
    const data = {
      file: file.file,
      sales_tax_return_id: cellData.id,
    };

    this.props.downloadSchedulesFile(data).then((toDownload) => {
      const a = document.createElement("a");
      a.setAttribute("href", toDownload.link);
      a.setAttribute("download", fileName);
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(toDownload.link);
      document.body.removeChild(a);
    });
  };

  resetStatusMessages = () => {
    this.setState({
      status: false,
      changeTaxReturnSuccess: false,
      errorChangeTaxReturn: false,
    });
  };

  resetUploadFileDialogData = () => {
    const fileNameList = {
      tax_return_doc: [],
    };

    this.setState({
      fileNameList,
      sendTaxFileSuccess: false,
      sendingTaxFileError: false,
    });

    this.docsFiles = {
      tax_return_doc: [],
    };
  };

  doEditTaxReturnStatus = (cellData) => {
    const { status } = this.state;

    const data = {
      id: cellData.id,
      status,
    };
    this.props
      .editTaxReturnStatus(data)
      .then(() => {
        this.props.fetchDeclarationsSalesTax(this.state.query);
        this.setState({ changeTaxReturnSuccess: true });
      })
      .catch((err) => {
        this.setState({ errorChangeTaxReturn: err.error });
      });
  };

  doDeleteFile = (fileListName, fileName) => {
    const { fileNameList } = this.state;
    let fileToDelete = undefined;
    _.map(this.docsFiles[fileListName], (file, index) => {
      if (file.name === fileName) {
        fileToDelete = index;
      } // get index
    });

    if (fileToDelete !== undefined) {
      this.docsFiles[fileListName].splice(fileToDelete, 1); // delete file name the list
      fileNameList[fileListName].splice(fileToDelete, 1); // delete file name from the list
      this.setState({ fileNameList });
    }
  };

  doSubmitDeclaration = (id) => {
    this.props
      .submitSalesTax(id)
      .then(() => {
        this.props.fetchDeclarationsSalesTax(this.state.query);
        this.handleActionDialogsClose("submit");
      })
      .catch(_.noop);
  };

  docsFiles = {
    tax_return_doc: [],
  };

  attachFiles = (docsFiles) => {
    // attach documents to form
    const formDataFiles = new FormData();
    for (let i = 0; i < docsFiles.tax_return_doc.length; i++) {
      formDataFiles.append(
        "file",
        docsFiles.tax_return_doc[i],
        docsFiles.tax_return_doc[i].name
      );
    }
    return formDataFiles;
  };

  doFilter = (query) => {
    if (query.state_code === "all") {
      query.state_code = undefined;
    }

    query.from_date = moment()
      .year(query.from_year)
      .quarter(query.from_quarter)
      .startOf("quarter")
      .format("YYYY-MM-DD");
    query.to_date = moment()
      .year(query.to_year)
      .quarter(query.to_quarter)
      .endOf("quarter")
      .format("YYYY-MM-DD");

    const pagination = _.pick(this.state.query, ["offset", "limit"]);
    this.setState(
      {
        query: _.assign(query, pagination),
      },
      () => {
        this.props.fetchDeclarationsSalesTax(this.state.query);
      }
    );
  };

  doSendTaxReturnFile = () => {
    const { actionDialogs, fileNameList } = this.state;
    let file = null;
    if (fileNameList.tax_return_doc.length > 0) {
      file = this.attachFiles(this.docsFiles);
      this.props
        .sendTaxReturnFile(actionDialogs.cellData.id, file)
        .then(() => {
          this.props.fetchDeclarationsSalesTax(this.state.query);
          this.setState({ sendTaxFileSuccess: true });
          this.handleActionDialogsClose("upload");
          this.resetUploadFileDialogData();
        })
        .catch((result) => {
          this.setState({ sendingTaxFileError: result });
        });
    }
  };

  doDeleteTaxDeclaration = (id) => {
    this.props
      .deleteSalesTax(id)
      .then(() => {
        this.props.fetchDeclarationsSalesTax(this.state.query);
        this.handleActionDialogsClose("delete");
      })
      .catch(_.noop);
  };

  renderDeleteSalesTaxDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;
    const data = actionDialogs.cellData;

    const onClose = () => {
      this.handleActionDialogsClose("delete");
    };

    return !_.isEmpty(data) ? (
      <Modal
        title={t("vatReturns.delete")}
        description={
          <div style={{ maxWidth: "300px", margin: "0 auto" }}>
            {`Do you want delete ${data.name} for the period ${data.period} ${data.period_type} ${data.period_year}?`}
          </div>
        }
        onClose={onClose}
        visible={actionDialogs.delete.open}
        contentOnCenter
        iconType='danger'
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("vatReturns.cancel")}
            </Button>
            <Button
              primary
              size='large'
              destructive
              onClick={() => this.doDeleteTaxDeclaration(data.id)}
            >
              {t("vatReturns.delete")}
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  }

  renderChangeStatusDialog = () => {
    const {
      actionDialogs,
      status,
      changeTaxReturnSuccess,
      errorChangeTaxReturn,
    } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={`${t("vatReturns.taxReturn")}
            ${
              actionDialogs.cellData &&
              actionDialogs.cellData.id &&
              ` ${actionDialogs.cellData.period} ${actionDialogs.cellData.period_type} ${actionDialogs.cellData.period_year} ${actionDialogs.cellData.state.name}`
            }
        `}
        visible={actionDialogs.status.open}
        infoIconVisible={false}
        onClose={() => {
          this.handleActionDialogsClose("status");
          this.resetStatusMessages();
        }}
        content={
          <FormLayout>
            <br />
            {changeTaxReturnSuccess && (
              <div>
                <Banner status='success'>
                  {t("vatReturns.statusChanged")}
                </Banner>
                <br />
              </div>
            )}
            {errorChangeTaxReturn && (
              <div>
                <Banner status='critical'>{errorChangeTaxReturn}</Banner>
                <br />
              </div>
            )}
            <FormLayout.Group>
              <Select
                id='status'
                label={t("vatReturns.selectStatus")}
                options={this.statusArr}
                onChange={(value) => this.setState({ status: value })}
                value={
                  status
                    ? status
                    : actionDialogs.cellData && actionDialogs.cellData.status
                }
              />
              <br />
            </FormLayout.Group>
          </FormLayout>
        }
        footer={
          <Button
            primary
            disabled={!status}
            onClick={() => this.doEditTaxReturnStatus(actionDialogs.cellData)}
          >
            {t("vatReturns.edit")}
          </Button>
        }
      />
    );
  };

  renderSalesTaxPreviewDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return !_.isEmpty(actionDialogs.cellData) ? (
      <Modal
        title={`${actionDialogs.cellData.name} details`}
        visible={actionDialogs.preview.open}
        description={`${actionDialogs.cellData.period} ${
          actionDialogs.cellData.period_type
        } ${actionDialogs.cellData.period_year} ${
          actionDialogs.cellData.state
            ? ` ${t("vatReturns.forPeriod2")} ${
                actionDialogs.cellData.state.name
              }`
            : ""
        }`}
        infoIconVisible={false}
        onClose={() => this.handleActionDialogsClose("preview")}
        content={
          <>
            <br />
            <ReturnPreview
              preview={actionDialogs.cellData.result}
              currency={actionDialogs.cellData.currency}
            />
            {!_.isEmpty(actionDialogs.cellData.files) && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-extra-tight)', alignItems: 'flex-end' }}>
                <br />
                <Text>Files available to download: </Text>
                {actionDialogs.cellData.files.map((file, ind) => (
                  <Button
                    key={`sch2${ind}`}
                    onClick={() =>
                      this.downloadSchedules(file, actionDialogs.cellData)
                    }
                    plain
                  >
                    {file.name}
                  </Button>
                ))}
              </div>
            )}
          </>
        }
      />
    ) : undefined;
  }

  renderSubmitDialog() {
    const { actionDialogs, isChecked, error } = this.state;
    const { t } = this.props;

    const onClose = () => {
      this.handleActionDialogsClose("submit");
      this.setState({
        error: false,
        isChecked: false,
      });
    };

    return !_.isEmpty(actionDialogs.cellData) ? (
      <Modal
        title={`Auto-submit ${actionDialogs.cellData.name} for the amount ${actionDialogs.cellData.total_sum} $`}
        visible={actionDialogs.submit.open}
        description={`${actionDialogs.cellData.name} for ${
          actionDialogs.cellData.state.code
            ? `${actionDialogs.cellData.state.name}${t(
                "vatReturns.wantSubmit3"
              )}`
            : ""
        } for ${actionDialogs.cellData.period}
      ${actionDialogs.cellData.period_type} ${
          actionDialogs.cellData.period_year
        }`}
        infoIconVisible={false}
        onClose={onClose}
        content={
          <>
            <div style={{ padding: "0.5rem 0px" }}>
              <Text variant='bodyLg'>30 $ auto-submit</Text>
            </div>
            <ReturnPreview
              preview={actionDialogs.cellData.result}
              currency={actionDialogs.cellData.currency}
            />
            <br />
            {!error && (
              <Checkbox
                label={t("salesTaxReturns.clickObSubmit")}
                checked={isChecked}
                onChange={(value) => this.setState({ isChecked: value })}
              />
            )}
            {error && (
              <Text variant="bodyMd" color="critical">
                Error: {this.state.error}
              </Text>
            )}
          </>
        }
        footer={
          <>
            <div
              style={{ width: "60%", marginLeft: "auto", marginTop: "1rem" }}
            >
              <ButtonGroup fullWidth>
                <Button onClick={onClose}>{t("vatReturns.cancel")}</Button>
                <Button
                  primary
                  onClick={() => {
                    if (actionDialogs.cellData.is_autosubmit) {
                      this.doSubmitDeclaration(actionDialogs.cellData.id);
                    } else {
                      const data = { ...actionDialogs.cellData };
                      this.handleActionDialogsClose("submit");
                      this.handleActionDialogsOpen("payAutosubmit", data);
                      this.setState({ isChecked: false });
                    }
                  }}
                  disabled={!isChecked}
                >
                  {t("vatReturns.submit")}
                </Button>
              </ButtonGroup>
            </div>
          </>
        }
      />
    ) : undefined;
  }

  renderPayAutosubmitDialog() {
    const { actionDialogs, action } = this.state;
    const { t } = this.props;

    const data = actionDialogs.cellData;

    const onClose = () => {
      this.handleActionDialogsClose("payAutosubmit");
      this.setState({
        error: false,
        isChecked: false,
      });
    };

    return !_.isEmpty(data) ? (
      <Modal
        title={`${actionDialogs.cellData.name}`}
        description={`${
          actionDialogs.cellData.state.code
            ? `${actionDialogs.cellData.state.name}${t(
                "vatReturns.wantSubmit3"
              )}`
            : ""
        } for ${actionDialogs.cellData.period}
          ${actionDialogs.cellData.period_type} ${
          actionDialogs.cellData.period_year
        }`}
        onClose={onClose}
        infoIconVisible={false}
        visible={actionDialogs.payAutosubmit.open}
        content={
          <>
            <br />
           <Box
             display="flex"
             flexDirection="column"
             gap="2"
           >
              <Checkbox
                label={"30 $ auto-submit"}
                checked={action === "money"}
                onChange={() =>
                  this.setState(() => ({
                    action: "money",
                  }))
                }
              />
              <Checkbox
                label={"Use credits"}
                checked={action === "credits"}
                onChange={() =>
                  this.setState(() => ({
                    action: "credits",
                  }))
                }
              />
            </Box>
          </>
        }
        footer={
          <StripePayment
            data={{
              sales_tax_return_id: data.id,
              period: actionDialogs.cellData.period,
              period_type: actionDialogs.cellData.period_type,
              year: actionDialogs.cellData.period_year,
              action,
            }}
            onClose={onClose}
            service={"tax-return"}
            onCreditsPayment={() => {
              this.props.submitSalesTax(data.id).then(() => {
                onClose();
                this.props.fetchDeclarationsSalesTax();
              });
            }}
          />
        }
      />
    ) : undefined;
  }

  renderUploadFileDialog = () => {
    const {
      actionDialogs,
      sendingTaxFileError,
      sendTaxFileSuccess,
      fileNameList,
    } = this.state;
    const { t } = this.props;

    return (
      !_.isEmpty(actionDialogs.cellData) && (
        <Modal
          title={`${t("vatReturns.taxReturn")}
              ${
                actionDialogs.cellData &&
                actionDialogs.cellData.id &&
                `${actionDialogs.cellData.period} ${actionDialogs.cellData.period_type} ${actionDialogs.cellData.period_year} ${actionDialogs.cellData.state.name}`
              }`}
          infoIconVisible={false}
          content={
            <>
              <br />
              {sendTaxFileSuccess && (
                <div>
                  <Banner status='success'>{t("vatReturns.fileAdded")}</Banner>
                  <br />
                </div>
              )}
              {sendingTaxFileError && (
                <div>
                  <Banner status='critical'>{sendingTaxFileError}</Banner>
                  <br />
                </div>
              )}
              {this.renderMultipleFileSelect("tax_return_doc")}
            </>
          }
          footer={
            <Button
              primary
              disabled={
                sendTaxFileSuccess || _.isEmpty(fileNameList.tax_return_doc)
              }
              onClick={() => this.doSendTaxReturnFile()}
            >
              {t("vatReturns.uploadFile")}
            </Button>
          }
          onClose={() => {
            this.handleActionDialogsClose("upload");
            this.resetUploadFileDialogData();
          }}
          visible={actionDialogs.upload.open}
        />
      )
    );
  };

  renderMultipleFileSelect = (docType) => {
    const { fileNameList } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button plain onClick={() => this.handleFileSelect(docType)}>
            {t("vatReturns.addFile")}
            <input
              type='file'
              multiple
              style={{ display: "none" }}
              ref={(input) => (this.fileUploadInput = input)}
            />
          </Button>
          <Text variant="bodyMd" color="critical">
            {t("vatReturns.chooseFile")}
          </Text>
        </div>
        <div>
          {_.map(fileNameList[docType], (fileName, index) => {
            return (
              <div
                style={{ display: "inline-block", margin: "5px" }}
                key={index}
              >
                <Tag
                  key={index}
                  onRemove={() => {
                    this.doDeleteFile(docType, fileName);
                  }}
                >
                  {fileName}
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const { query, pagination, quarters, years } = this.state;
    const { declarationsSalesTax, fetchingDeclarations, usStates, t, taxPermits } =
      this.props;

    const rows = declarationsSalesTax
      ? declarationsSalesTax.items.map((item) => ({
          ...item,
          total_sum: preciseRound(item.total_sum, 2),
        }))
      : [];

    const columns = [
      {
        header: {
          label: t("vatReturns.period"),
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [
            (value, { rowData }) =>
              `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
          ],
        },
      },
      {
        property: "name",
        header: {
          label: t("vatReturns.nameDoc"),
        },
      },
      {
        property: "state",
        header: {
          label: "State",
        },
        cell: {
          formatters: [(value) => value.name],
        },
      },
      {
        property: "total_sum",
        header: {
          label: "TAX amount",
        },
      },
      {
        property: "currency",
        header: {
          label: t("vatReturns.currency"),
        },
      },
      {
        property: "created_at",
        header: {
          label: t("vatReturns.fillDate"),
        },
        cell: {
          formatters: [
            (value) => (
              <span style={{ whiteSpace: "nowrap" }}>{formatDate(value)}</span>
            ),
          ],
        },
      },
      {
        property: "created_at",
        header: {
          label: t("vatReturns.time"),
        },
        cell: {
          formatters: [(value) => formatTime(value)],
        },
      },
      {
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              const visiblePreview =
                rowData.status === "new" ||
                rowData.status === "pending" ||
                rowData.status === "ready" ||
                rowData.status === "submitted";
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap="4"
                >
                  {visiblePreview && (
                    <Tooltip content={t("vatReturns.view")}>
                      <Button
                        plain
                        onClick={() => {
                          this.props
                            .getSalesTaxPreview(rowData.id)
                            .then((data) => {
                              this.handleActionDialogsOpen("preview", {
                                ...rowData,
                                ...data,
                              });
                            })
                            .catch(_.noop);
                        }}
                        icon={<img src={eyeIMG} alt='view' />}
                      />
                    </Tooltip>
                  )}
                </Box>
              );
            },
          ],
        },
      },
      {
        property: "status",
        header: {
          label: t("vatReturns.status"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              let badgeComponent = false;
              switch (value) {
                case "submitted":
                  badgeComponent = <Badge status={"success"}>{value}</Badge>;
                  break;
                case "new":
                  badgeComponent = <Badge status={"info"}>{value}</Badge>;
                  break;
                case "declined":
                  badgeComponent = <Badge status={"warning"}>{value}</Badge>;
                  break;
                case "draft":
                  badgeComponent = <Badge>{value}</Badge>;
                  break;
                default:
                  badgeComponent = <Badge status={"attention"}>{value}</Badge>;
              }

              return (
                <span style={{ whiteSpace: "nowrap" }}>
                  {this.props.user.is_manager && (
                    <Tooltip content={t("vatReturns.edit")}>
                      <Button
                        plain
                        onClick={() =>
                          this.handleActionDialogsOpen("status", rowData)
                        }
                      >
                        <img src={editIMG} alt='edit' />
                      </Button>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                    </Tooltip>
                  )}
                  {badgeComponent}
                </span>
              );
            },
          ],
        },
      },
      {
        property: "file_link",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              const visibleDel =
                rowData.status !== "submitted" &&
                rowData.status !== "ready" &&
                rowData.status !== "pending";
              return (
                <Box
                  display="flex"
                  alignItems="end"
                  justifyContent="center"
                  gap="4"
                >
                  {rowData.status === "new" && (
                    <Tooltip content={"Auto-submit"}>
                      <Button
                        plain
                        onClick={() => {
                          this.props
                            .getSalesTaxPreview(rowData.id)
                            .then((data) => {
                              this.handleActionDialogsOpen("submit", {
                                ...rowData,
                                ...data,
                              });
                            })
                            .catch(_.noop);
                        }}
                        icon={<img src={submitIMG} alt='submit' />}
                      />
                    </Tooltip>
                  )}

                  {this.props.user.is_manager && (
                    <Tooltip content={t("vatReturns.uploadFile")}>
                      <Button
                        plain
                        onClick={() =>
                          this.handleActionDialogsOpen("upload", rowData)
                        }
                        icon={<img src={importIMG} alt='import' />}
                      />
                    </Tooltip>
                  )}

                  {visibleDel && rowData.status === "new" && (
                    <Tooltip content={t("vatReturns.delete")}>
                      <Button
                        plain
                        onClick={() =>
                          this.handleActionDialogsOpen("delete", rowData)
                        }
                        icon={<img src={deleteIMG} alt='delete' />}
                      />
                    </Tooltip>
                  )}
                </Box>
              );
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);
    const paginatedRows = paginate(pagination)(sortedRows);

    const fromQuarters = [];
    quarters.map((quarter) => {
      fromQuarters.push({
        key: quarter.value,
        label: quarter.label,
        value: quarter.value.toString(),
      });
    });

    const fromYears = [];
    years.map((year) => {
      fromYears.push({
        key: year.value,
        label: year.label,
        value: year.value.toString(),
      });
    });

    const tillQuarters = [];
    quarters.map((quarter) => {
      tillQuarters.push({
        key: quarter.value,
        label: quarter.label,
        value: quarter.value.toString(),
      });
    });

    const tillYears = [];
    years.map((year) => {
      tillYears.push({
        key: year.value,
        label: year.label,
        value: year.value.toString(),
      });
    });

    return (
      <Page
        title={
          <Text variant='heading3xl' as='span'>
            {t("salesTaxReturns.salesTax")}
          </Text>
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            {t("salesTaxReturns.fillSalesTax")}
          </Text>
        }
        fullWidth
        separator
        primaryAction={
          <div
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <div>
              <Button
                primary
                onClick={() =>
                  this.props.navigate("/tax-returns/create-return")
                }
              >
                {t("salesTaxReturns.fillTaxReturn")}
              </Button>
            </div>
          </div>
        }
      >
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <FormLayout>
                <FormLayout.Group condensed>
                  <Select
                    id='fromQuarter'
                    name='from_quarter'
                    label={`${t("vatReturns.fromQ")}:`}
                    options={fromQuarters}
                    onChange={(value) => {
                      query.from_quarter = +value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                    value={query.from_quarter.toString()}
                  />
                  <Select
                    id='fromYears'
                    name='from_year'
                    label={`${t("vatReturns.fromY")}:`}
                    options={fromYears}
                    onChange={(value) => {
                      query.from_year = +value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                    value={query.from_year.toString()}
                  />
                  <Select
                    id='tillQuarter'
                    name='to_quarter'
                    label={`${t("vatReturns.tillQ")}:`}
                    options={tillQuarters}
                    onChange={(value) => {
                      query.to_quarter = +value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                    value={query.to_quarter.toString()}
                  />
                  <Select
                    id='tillYears'
                    name='to_years'
                    label={`${t("vatReturns.tillY")}:`}
                    options={tillYears}
                    onChange={(value) => {
                      query.to_year = +value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                    value={query.to_year.toString()}
                  />
                  <Select
                    id='customerState'
                    name='customer_state'
                    label={`State:`}
                    options={[
                      {
                        label: "All states",
                        value: "all",
                      },
                      ...taxPermits?.map((state) => ({
                        label: state.state,
                        value: state.state_code,
                      })),
                    ]}
                    value={query.state_code || "all"}
                    onChange={(value) => {
                      query.state_code = value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card>

            <Card>
              {fetchingDeclarations ? (
                <Card.Section>
                  <Spinner />
                </Card.Section>
              ) : (
                <>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                    ]}
                    headings={columns.map(({ header }) => (
                      <Text fontWeight='semibold'>{header.label}</Text>
                    ))}
                    rows={paginatedRows.rows}
                  />
                  {_.isEmpty(sortedRows) && (
                    <NoDataMessage
                      description='Add transactions to see data'
                      buttonText='Add transactions'
                      onClick={() =>
                        this.props.navigate("/tax-returns/create-return")
                      }
                    />
                  )}
                </>
              )}
            </Card>
            {!_.isEmpty(sortedRows) && (
              <Box padding='4'>
                <Pagination
                  total={this.props.declarationsSalesTax.count}
                  current={pagination.page}
                  pageSize={defaultLimit}
                  onChange={(current) => this.onSelectPage(current)}
                />
              </Box>
            )}
          </Layout.Section>
        </Layout>
        {this.renderSalesTaxPreviewDialog()}
        {this.renderSubmitDialog()}
        {this.renderChangeStatusDialog()}
        {this.renderUploadFileDialog()}
        {this.renderDeleteSalesTaxDialog()}
        {this.renderPayAutosubmitDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  declarationsSalesTax: state.declarations.declarationsSalesTax,
  fetchingDeclarations: state.declarations.fetchingSalesTax,
  returnPreview: state.declarations.returnPreview,
  defaultLanguage: state.user.defaultLanguage,
  taxPermits: state.declarations.taxPermits,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchStates: () => dispatch(fetchSalesTaxPermits(true)),
    fetchDeclarationsSalesTax: (params) =>
      dispatch(fetchDeclarationsSalesTax(params)),
    getSalesTaxPreview: (id) => dispatch(getSalesTaxPreview(id)),
    editTaxReturnStatus: (params) => dispatch(editTaxReturnStatus(params)),
    sendTaxReturnFile: (id, file) => dispatch(sendTaxReturnFile({ id, file })),
    deleteSalesTax: (id) => dispatch(deleteSalesTax(id)),
    downloadSchedulesFile: (params) => dispatch(downloadSchedulesFile(params)),
    submitSalesTax: (id) => dispatch(submitSalesTax(id)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(SalesTaxReturns))
);
