import moment from "moment";
import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Datepicker from "components/Datepicker/Datepicker";
import { formatDate, formatDateForServer, formatTime } from "utils/dates";
import { getHmrcData } from "utils/hmrcData";
import withRouter from "helpers/withRouter";
import _ from "lodash";
import { parseServerError } from "utils/errors";
import {
  Badge,
  Banner, Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  DataTable,
  FormLayout,
  Layout,
  Page,
  Select,
  Text,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { Link } from "react-router-dom";
import formatRow from "utils/formatRow";
import deleteIMG from "img/delete.svg";
import ClipIMG from "img/clip.svg";
import PageHelmet from "components/PageHelmet";
import Modal from "components/Modal/Modal";
import paginate from "utils/paginate";
import { getCookie, setCookie } from "utils/cookie";
import { getYears } from "utils/years";
import eyeIMG from "img/eye.svg";
import downloadIMG from "img/download.svg";
import vatSubmitIMG from "img/vatSubmit.svg";
import editIMG from "img/edit.svg";
import SearchingCountry from '../../components/SearchingCountry/SearchingCountry'
import {
  changeDac7Status,
  create1099Report,
  createDac7Report, deleteDac7Report,
  fetchDac7Reports, getDac7Preview,
  getLiabilities,
  getObligations, submitDac7Report
} from '../../redux/features/declarations/declarationsSlice'
import {
  fetchNewMessagesCount,
  fetchTaxAuditMassages,
  resetSystemMessage
} from '../../redux/features/messenger/messengerSlice'
import { fetchCountries } from '../../redux/features/other/otherSlice'
import {
  createAuditReport,
  deleteTaxAuditReport,
  fetchReportTypes,
  fetchTransactionsAuditListReports
} from '../../redux/features/transactions/transactionsSlice'
import { fetchCompanyDefaultLanguage } from '../../redux/features/user/userSlice'
import { createSchemas } from "../Dac7/schemas";
import { createCustomDispatch } from "helpers/customDispatch";
import BackArrowWithComponent from "components/BackArrow";
import Dac7Reporting from './DAC7Reporting'

const defaultLimit = 25;

const defaultDates = {
  from: moment().startOf("month").toDate(),
  to: moment().toDate(),
};

const years = getYears({ yearsBefore: 1, yearsAfter: 0 });

function Typography(props) {
  return null
}

Typography.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node
}

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortingColumns: [],

      data: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        selected: {
          start: defaultDates.from,
          end: defaultDates.to,
        },
        visibilityDatePickerFrom: false,
        visibilityDatePickerTo: false,

        consumer_country: "all",
        from_date: formatDateForServer(defaultDates.from),
        to_date: formatDateForServer(defaultDates.to),
        vat_number: "",
      },

      liabData: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        selected: {
          start: defaultDates.from,
          end: defaultDates.to,
        },
        visibilityDatePickerFrom: false,
        visibilityDatePickerTo: false,

        consumer_country: "GBR", // only UK available
        from_date: formatDateForServer(defaultDates.from),
        to_date: formatDateForServer(defaultDates.to),
      },

      obligData: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        selected: {
          start: defaultDates.from,
          end: defaultDates.to,
        },
        visibilityDatePickerFrom: false,
        visibilityDatePickerTo: false,

        consumer_country: "GBR", // only UK available
        from_date: formatDateForServer(defaultDates.from),
        to_date: formatDateForServer(defaultDates.to),
      },

      eprData: {
        consumer_country: "all",
        registration_number: "",
        system_operator: "",
        year: "",
      },

      pagination: {
        page: 1,
        perPage: defaultLimit,
      },

      dac7: {
        businessName: "",
        period: "",
      },

      dac7ImmovableProperty: {
        businessName: "",
        period: "",
      },

      report1099: {
        businessName: "",
        period: "",
      },

      isConfirmSubmitDialog: false,

      actionDialogs: {
        hmrcIntegration: { open: false },
        deleteReport: { open: false },
        subs: { open: false },
        deleteDac7Report: { open: false },
        changeDac7Status: { open: false },
        dac7Preview: { open: false },
        dac7Submit: { open: false },
      },
    };
  }

  componentDidMount() {
    this.props.fetchNewMessagesCount();
    this.props.fetchTaxAuditMassages();
    this.props.fetchCountries();
    this.props.fetchCompanyDefaultLanguage();
    this.props.fetchTransactionsAuditListReports();
    this.props.fetchReportTypes();
    this.props.fetchDac7Reports();
    this.props.fetchDac7Reports({ is_immovable: true });
  }

  schemas = createSchemas({ t: this.props.t });

  getAllCountriesForSelect = () => {
    const optionsCountries = [];
    this.props.countries.map((country) => {
      optionsCountries.push({
        label: country.name_no_article,
        value: country.code,
      });
    });
    return optionsCountries;
  };

  getBannerInfo = (message) => {
    let title = "";
    let status = "";
    let text = "";
    switch (message.status) {
      case "ready":
        title = "Success";
        status = "success";
        text = `Tax audit report for ${message.country} from ${formatDate(
          message.from_date
        )} to ${formatDate(message.to_date)} was successfully generated`;
        break;
      case "done_no_data":
        title = "Warning";
        status = "warning";
        text = `Tax audit report for ${message.country} from ${formatDate(
          message.from_date
        )} to ${formatDate(message.to_date)} does not contain data`;
        break;
      case "not_paid_period":
        title = "Error";
        status = "critical";
        text = `The period from ${formatDate(
          message.from_date
        )} to ${formatDate(
          message.to_date
        )} is not included in the subscription`;
        break;
      default:
        title = "Error";
        status = "critical";
        text = "Something went wrong...";
    }

    return {
      title,
      status,
      text,
    };
  };

  downloadFile(link) {
    const from = link.indexOf("/") + 1;
    const fileName = link.substring(from);
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("download", fileName);
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(link);
    document.body.removeChild(a);
  }

  deleteReport = (id) => {
    const data = {
      id: `${id}`,
    };
    this.props
      .deleteTaxAuditReport(data)
      .then(() => {
        this.props.fetchTransactionsAuditListReports().then(() => {
          this.props.fetchTaxAuditMassages();
          this.props.fetchNewMessagesCount();
          this.handleActionDialogsClose("deleteReport");
        });
      })
      .catch(_.noop);
  };

  doReceiveLiabilities = () => {
    const { liabData } = this.state;
    const params = {
      from_date: formatDateForServer(liabData.from_date),
      to_date: formatDateForServer(liabData.to_date),
      country_id: liabData.consumer_country,
      headers: getHmrcData(),
    };
    this.props
      .getLiabilities(params)
      .then((resp) => {
        if (resp.hasOwnProperty("hmrc_integration") && !resp.hmrc_integration) {
          this.handleActionDialogsOpen("hmrcIntegration");
        }
        const liabilities = resp;
        this.setState({ liabilities });
      })
      .catch(_.noop);
  };

  doReceiveObligations = () => {
    const { obligData } = this.state;
    const params = {
      from_date: formatDateForServer(obligData.from_date),
      to_date: formatDateForServer(obligData.to_date),
      country_id: obligData.consumer_country,
      headers: getHmrcData(),
    };
    this.props
      .getObligations(params)
      .then((resp) => {
        if (resp.hasOwnProperty("hmrc_integration") && !resp.hmrc_integration) {
          this.handleActionDialogsOpen("hmrcIntegration");
        }
        const obligations = resp;
        this.setState({ obligations });
      })
      .catch(_.noop);
  };

  makePromise = () => {
    const { data } = this.state;
    const { t } = this.props;
    const params = {
      report_type: data.type,
      from_date: formatDateForServer(data.from_date),
      to_date: formatDateForServer(data.to_date),
    };

    if (data.vat_number) {
      params.vat_number = data.vat_number;
    }

    if (data.consumer_country !== "all") {
      params.country_code = data.consumer_country;
    }

    if (data.type === "_GET_MTR_" && data.merch) {
      params.supplier_id = data.merch;
    }

    this.props
      .createAuditReport(params)
      .then((resp) => {
        if (resp) {
          this.props.fetchTransactionsAuditListReports();
          this.props.fetchNewMessagesCount(); // for empty re-import
          this.props.fetchTaxAuditMassages(); // for empty re-import
          this.setState({ fetchingError: false });
        } else {
          this.setState({ fetchingError: t("reports.noTrans") });
        }
      })
      .catch((response) => {
        this.setState({ fetchingError: parseServerError(response) });
      });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleChangeDate = (date, key) => {
    const dateObj = this.state[key];

    if (date.start) {
      dateObj.from_date = formatDateForServer(date.start);
    }

    if (date.end) {
      dateObj.to_date = formatDateForServer(date.end);
      dateObj.year = new Date(date.end).getFullYear();
    }

    this.setState({ [key]: dateObj });
  };

  generateShowingInfo = (page, pages, count) => {
    const { t } = this.props;
    let message = "";
    if (count < 25) {
      message = `${t("reports.showing")} ${count} ${t(
        "reports.of"
      )} ${count} ${t("reports.results")}`;
    } else {
      if (page !== pages) {
        message = `${t("reports.showing")} ${page * 25 - 24}-${page * 25} ${t(
          "reports.of"
        )} ${count} ${t("reports.results")}`;
      } else {
        message = `${t("reports.showing")} ${page * 25 - 24}-${count} ${t(
          "reports.of"
        )} ${count} ${t("reports.results")}`;
      }
    }
    return message;
  };

  renderHMRCDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    const onClose = () => {
      this.handleActionDialogsClose("hmrcIntegration");
    };

    const onConnect = () => {
      if (!getCookie("api_key")) {
        const apiKey = localStorage.getItem("api_key");
        const now = new Date();
        const nextWeek = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

        setCookie("api_key", apiKey, {
          expires: nextWeek,
          path: "/",
        });
      }

      window.location = `${window.location.protocol}//${window.location.hostname}/api/1/integrations/hmrc`;
    };

    return (
      <Modal
        title={t("reports.hmrcInt")}
        description={<p>{t("reports.connectHMRC")}</p>}
        onClose={onClose}
        visible={actionDialogs.hmrcIntegration.open}
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("reports.close")}
            </Button>
            <Button primary onClick={onConnect} size='large'>
              {t("reports.connect")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  renderDeleteReportDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;
    return (
      <Modal
        title={`Delete report`}
        iconType={"danger"}
        onClose={() => this.handleActionDialogsClose("deleteReport")}
        description={<p>Do you want delete the report?</p>}
        visible={actionDialogs.deleteReport.open}
        footer={
          <ButtonGroup fullWidth>
            <Button
              onClick={() => this.handleActionDialogsClose("deleteReport")}
              size='large'
            >
              {t("reports.cancel")}
            </Button>
            <Button
              primary
              onClick={() => this.deleteReport(actionDialogs.cellData.id)}
              destructive
              size='large'
            >
              {t("reports.delete")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  renderDac7Preview() {
    const { actionDialogs } = this.state;
    const { dac7Preview, t } = this.props;
    const sortedRows = formatRow(dac7Preview, this.schemas.tableSchema);
    const onClose = () => this.handleActionDialogsClose("dac7Preview");
    return (
      actionDialogs.cellData && (
        <Modal
          title={
            actionDialogs.cellData.is_immovable
              ? t("dac7.dac7PreviewImmovable")
              : t("dac7.dac7Preview")
          }
          infoIconVisible={false}
          onClose={onClose}
          visible={actionDialogs.dac7Preview.open}
          content={
              <>
                <br />
                <Card>
                  <DataTable
                    columnContentTypes={["text", "text", "text", "text", "numeric"]}
                    headings={this.schemas.tableSchema.map(({ header }) => (
                      <Text fontWeight="semibold" key={header.label}>
                        {header.label}
                      </Text>
                    ))}
                    rows={sortedRows}
                    hideScrollIndicator
                    hoverable
                    increasedTableDensity
                  />
                </Card>
              </>
          }
        />
      )
    );
  }

  renderDac7Submit() {
    const { actionDialogs, isConfirmSubmitDialog } = this.state;
    const { dac7Preview, submittingDac7Report, t } = this.props;
    const sortedRows = formatRow(dac7Preview, this.schemas.tableSchema);
    const onClose = () => {
      this.handleActionDialogsClose("dac7Submit");
      this.setState(() => ({
        isConfirmSubmitDialog: false,
      }));
    };
    const onSubmit = () => {
      const { id, is_immovable } = actionDialogs.cellData;

      this.props.submitDac7Report(id).then(() => {
        this.props.fetchDac7Reports({ is_immovable });
        onClose();
      });
    };
    return (
      actionDialogs.cellData && (
        <Modal
          title={
            actionDialogs.cellData.is_immovable
              ? t("dac7.dac7SubmitImmovable")
              : t("dac7.dac7Submit")
          }
          infoIconVisible={false}
          onClose={onClose}
          visible={actionDialogs.dac7Submit.open}
          content={
            <>
              <br />
              <Card>
                <DataTable
                  columnContentTypes={[
                    "text",
                    "text",
                    "text",
                    "text",
                    "numeric",
                  ]}
                  headings={this.schemas.tableSchema.map(({ header }) => (
                    <Text fontWeight='semibold'>{header.label}</Text>
                  ))}
                  rows={sortedRows}
                  hideScrollIndicator
                  hoverable
                  increasedTableDensity
                />
              </Card>
            </>
          }
          footer={
            <Box
              display="flex"
              flexDirection="column"
              gap="4"
              width="100%"
            >
              <Checkbox
                label={
                  <Text variant='bodyMd' color='subdued'>
                    {t("dac7.clickObSubmit")}
                  </Text>
                }
                checked={isConfirmSubmitDialog}
                onChange={() => {
                  this.setState(() => ({
                    isConfirmSubmitDialog: !isConfirmSubmitDialog,
                  }));
                }}
              />
              <ButtonGroup fullWidth>
                <Button onClick={onClose}>{t("reports.cancel")}</Button>
                <Button
                  primary
                  onClick={onSubmit}
                  disabled={!isConfirmSubmitDialog}
                  loading={submittingDac7Report}
                >
                  {t("vatReturns.submit")}
                </Button>
              </ButtonGroup>
            </Box>
          }
        />
      )
    );
  }

  renderDeleteDac7ReportDialog() {
    const { actionDialogs } = this.state;
    const { t, deletingDac7Report, deletingDac7ReportError } = this.props;
    const onClose = () => this.handleActionDialogsClose("deleteDac7Report");
    const onSubmit = () => {
      this.props.deleteDac7Report(actionDialogs.cellData.id).then((res) => {
        if (res.status === 200) {
          onClose();
          this.props.fetchDac7Reports(actionDialogs.cellData.is_immovable);
        }
      });
    };
    return (
      actionDialogs.cellData && (
        <Modal
          title={`Delete report`}
          iconType={"danger"}
          onClose={onClose}
          description={`Do you want delete report for ${actionDialogs.cellData.business_name} for ${actionDialogs.cellData.period_year}?`}
          visible={actionDialogs.deleteDac7Report.open}
          footer={
            <>
              {deletingDac7ReportError && (
                <>
                  <Banner status='critical'>{deletingDac7ReportError}</Banner>
                  <br />
                </>
              )}
              <ButtonGroup fullWidth>
                <Button onClick={onClose} size='large'>
                  {t("reports.cancel")}
                </Button>
                <Button
                  primary
                  onClick={onSubmit}
                  destructive
                  size='large'
                  loading={deletingDac7Report}
                >
                  {t("reports.delete")}
                </Button>
              </ButtonGroup>
            </>
          }
        />
      )
    );
  }

  renderSubscriptionDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;
    return (
      <Modal
        title={`Warning`}
        iconType={"warning"}
        description={
          <p>
            {actionDialogs.cellData?.error ?
              "This functionality is not available because a paid subscription is required."
              :
                <h3 style={{marginBottom: 10}}>
                  {actionDialogs.cellData?.e?.error}
                </h3>
            }
          </p>
        }
        onClose={() => this.handleActionDialogsClose("subs")}
        visible={actionDialogs.subs.open}
        content={
          <Link className='Polaris-Link' to='/subscription'>
            {t("reports.selectPlan")}
          </Link>
        }
      />
    );
  }

  renderChangeDac7StatusDialog = () => {
    const { editingDac7Status, editingDac7StatusError, t } = this.props;
    const { actionDialogs, dac7status } = this.state;

    const statusArr = [
      {
        value: "new",
        label: "New",
      },
      {
        value: "pending",
        label: "Pending",
      },
      {
        value: "submitted",
        label: "Submitted",
      },
      {
        value: "declined",
        label: "Declined",
      },
    ];

    const onClose = () => {
      this.handleActionDialogsClose("changeDac7Status");
      this.setState({
        dac7status: null,
      });
    };

    const onSubmit = () => {
      const { id, is_immovable } = actionDialogs.cellData;
      this.props
        .changeDac7Status({
          id,
          status: dac7status,
        })
        .then((res) => {
          if (res) {
            this.props.fetchDac7Reports({ is_immovable });
            onClose();
          }
        });
    };

    return (
      actionDialogs.cellData && (
        <Modal
          title={"Change status"}
          visible={actionDialogs.changeDac7Status.open}
          infoIconVisible={false}
          onClose={onClose}
          content={
            <>
              <br />
              <Select
                id='status'
                label={t("vatReturns.selectStatus")}
                options={statusArr}
                onChange={(value) => {
                  this.setState({ dac7status: value });
                }}
                value={dac7status ?? actionDialogs.cellData.status}
              />
            </>
          }
          footer={
            <>
              {editingDac7StatusError && (
                <div>
                  <Banner status='critical'>{editingDac7StatusError}</Banner>
                  <br />
                </div>
              )}
              <ButtonGroup>
                <Button
                  primary
                  loading={editingDac7Status}
                  disabled={
                    !dac7status || dac7status === actionDialogs.cellData.status
                  }
                  size='large'
                  onClick={onSubmit}
                >
                  {t("vatReturns.edit")}
                </Button>
              </ButtonGroup>
            </>
          }
        />
      )
    );
  };

  renderTaxAuditSection() {
    const { data, fetchingError } = this.state;
    const { listReports, taxAuditMessages, reportTypes, currentPlan, user, t } =
      this.props;

    const columns = [
      {
        property: "country",
        header: {
          label: "Country",
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [(value) => value || t("reports.allCountries")],
        },
      },
      {
        property: "date_from",
        header: {
          label: "Date range",
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [
            (value, { rowData }) =>
              `${formatDate(rowData.period_from)} - ${formatDate(
                rowData.period_till
              )}`,
          ],
        },
      },
      {
        property: "ordered",
        header: {
          label: "Date request",
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [(value) => `${formatDate(value)} ${formatTime(value)}`],
        },
      },
      {
        property: "status",
        header: {
          label: "Status",
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [
            (value) => (
              <Badge status={value === "ready" ? "success" : "attention"}>
                {value}
              </Badge>
            ),
          ],
        },
      },
      {
        property: "report_type",
        header: {
          label: "Type",
          transforms: [this.state.sortable],
        },
      },
      {
        property: "id",
        header: {
          label: "",
          transforms: [],
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Box
                display="flex"
                alignItems="end"
                justifyContent="center"
                gap="4"
              >
                {rowData.status === "ready" && (
                  <Tooltip content={t("reports.save")}>
                    <Button
                      plain
                      onClick={() => this.downloadFile(rowData.file)}
                    >
                      <img src={ClipIMG} alt='clip' width='20' />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip content={t("reports.delete")}>
                  <Button
                    plain
                    onClick={() =>
                      this.handleActionDialogsOpen("deleteReport", rowData)
                    }
                  >
                    <img src={deleteIMG} alt='delete' />
                  </Button>
                </Tooltip>
              </Box>
            ),
          ],
        },
      },
    ];

    const sortedRows = formatRow(listReports, columns);

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("reports.titles.taxAudit")}
          </Text>
        }
      >
        {(!_.isEmpty(listReports) || !_.isEmpty(taxAuditMessages)) && (
          <Card>
            {!_.isEmpty(taxAuditMessages) && (
              <div style={{ padding: "1rem" }}>
                {taxAuditMessages.map((message) => {
                  const bannerInfo = this.getBannerInfo(message);
                  return (
                    <div key={message.id}>
                      <Banner
                        title={bannerInfo.title}
                        status={bannerInfo.status}
                        onDismiss={() => {
                          this.props
                            .resetSystemMessage({
                              modelsName: `tax_audit`,
                              id: message.id,
                            })
                            .then(() => this.props.fetchTaxAuditMassages())
                            .then(() => this.props.fetchNewMessagesCount())
                            .catch(_.noop);
                        }}
                      >
                        {bannerInfo.text}
                      </Banner>
                      <br />
                    </div>
                  );
                })}
              </div>
            )}
            {!_.isEmpty(listReports) && (
              <DataTable
                columnContentTypes={["text", "text", "text", "text", "text"]}
                headings={columns.map(({ header }) => (
                  <Text fontWeight='semibold'>{header.label}</Text>
                ))}
                rows={sortedRows}
              />
            )}
          </Card>
        )}
        <Card sectioned>
          {!_.isEmpty(listReports) && <br />}

          <FormLayout>
            <FormLayout.Group>
              <Select
                label={"Report type:"}
                options={[
                  { label: "", value: "" },
                  ...reportTypes.map((item) => ({
                    label: item.name,
                    value: item.type,
                  })),
                ]}
                onChange={(type) => {
                  const newData = { ...this.state.data };
                  newData.type = type;
                  newData.merch =
                    newData.type === "_GET_MTR_" ? newData.merch : "";
                  this.setState({ data: newData });
                }}
                value={data.type}
              />
              <br />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                type='text'
                disabled={data.consumer_country === "all"}
                label={`${t("reports.vatNumber")}:`}
                value={data.vat_number}
                onChange={(value) => {
                  const newData = { ...this.state.data };
                  newData.vat_number = value;
                  this.setState({ data: newData });
                }}
              />
              {/*<Select*/}
              {/*  label={`${t("reports.country")}:`}*/}
              {/*  options={[*/}
              {/*    { label: "All", value: "all" },*/}
              {/*    ...this.getAllCountriesForSelect(),*/}
              {/*  ]}*/}
              {/*  onChange={(country) => {*/}
              {/*    const newData = { ...this.state.data };*/}
              {/*    newData.consumer_country = country;*/}
              {/*    this.setState({ data: newData });*/}
              {/*  }}*/}
              {/*  value={data.consumer_country}*/}
              {/*/>*/}
              <SearchingCountry
                title={`${t("reports.country")}:`}
                placeholder={'All'}
                onChange={(country) => {
                  const newData = { ...this.state.data };
                  newData.consumer_country = country;
                  this.setState({ data: newData });
                }}
                dataCountriesList={[
                  { label: "All", value: "all" },
                  ...this.getAllCountriesForSelect(),
                ]}
                changeCountry={data.consumer_country}
              />
              {data.type === "_GET_MTR_" ? (
                <TextField
                  type='text'
                  disabled={!data.type}
                  label={"Merchant id:"}
                  value={data.merch}
                  onChange={(value) => {
                    const newData = { ...this.state.data };
                    newData.merch = value;
                    this.setState({ data: newData });
                  }}
                />
              ) : null}
            </FormLayout.Group>
            <FormLayout.Group>
              <Datepicker
                label={t("reports.from")}
                onChange={(start) => this.handleChangeDate({ start }, "data")}
                value={this.state.data.from_date}
                allowRange={false}
              />
              <Datepicker
                label={t("reports.till")}
                onChange={(end) => this.handleChangeDate({ end }, "data")}
                value={this.state.data.to_date}
                allowRange={false}
              />
            </FormLayout.Group>
          </FormLayout>
          <br />
          <Button
            primary
            disabled={!data.type}
            loading={this.props.creatingAuditRep}
            onClick={() =>
              (!currentPlan && !user.is_manager && !user.is_omp) ||
              (currentPlan &&
                !currentPlan.is_active &&
                !currentPlan.is_active_sales_tax &&
                !user.is_manager &&
                !user.is_omp)
                ? this.handleActionDialogsOpen("subs")
                : this.makePromise()
            }
          >
            {t("reports.buttons.taxAudit")}
          </Button>

          {fetchingError && (
            <div>
              <br />
              <Banner
                status='critical'
                onDismiss={() => this.setState({ fetchingError: false })}
              >
                {fetchingError}
              </Banner>
              <br />
            </div>
          )}
        </Card>
      </Layout.AnnotatedSection>
    );
  }

  renderVatLiabilitiesSection() {
    const { currentPlan, user, t } = this.props;
    const { liabData } = this.state;

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("reports.titles.vatLiab")}
          </Text>
        }
      >
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <Select
                label={`${t("reports.country")}:`}
                disabled
                options={this.getAllCountriesForSelect()}
                onChange={(country) => {
                  liabData.consumer_country = country;
                  this.setState({ liabData });
                }}
                value={liabData.consumer_country}
              />
              <br />
            </FormLayout.Group>
            <FormLayout.Group>
              <Datepicker
                allowRange={false}
                label={t("reports.from")}
                onChange={(start) =>
                  this.handleChangeDate({ start }, "liabData")
                }
                value={this.state.liabData.from_date}
              />
              <Datepicker
                label={t("reports.till")}
                allowRange={false}
                onChange={(end) => this.handleChangeDate({ end }, "liabData")}
                value={this.state.liabData.to_date}
              />
            </FormLayout.Group>
          </FormLayout>
          <br />
          <Button
            primary
            disabled={!liabData.consumer_country}
            onClick={() =>
              (!currentPlan && !user.is_manager && !user.is_omp) ||
              (currentPlan &&
                !currentPlan.sum &&
                !currentPlan.sum_sales_tax &&
                !user.is_manager &&
                !user.is_omp)
                ? this.handleActionDialogsOpen("subs")
                : this.doReceiveLiabilities()
            }
          >
            {t("reports.buttons.vatStatus")}
          </Button>
        </Card>
      </Layout.AnnotatedSection>
    );
  }

  renderVatObligationsSection() {
    const { currentPlan, user, t } = this.props;
    const { obligData } = this.state;

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("reports.titles.vatObl")}
          </Text>
        }
      >
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <Select
                label={`${t("reports.country")}:`}
                disabled
                options={this.getAllCountriesForSelect()}
                onChange={(country) => {
                  obligData.consumer_country = country;
                  this.setState({ obligData });
                }}
                value={obligData.consumer_country}
              />
              <br />
            </FormLayout.Group>
            <FormLayout.Group>
              <Datepicker
                label={t("reports.from")}
                onChange={(start) =>
                  this.handleChangeDate({ start }, "obligData")
                }
                value={this.state.obligData.from_date}
                allowRange={false}
              />
              <Datepicker
                label={t("reports.till")}
                onChange={(end) => this.handleChangeDate({ end }, "obligData")}
                value={this.state.obligData.to_date}
                allowRange={false}
              />
            </FormLayout.Group>
          </FormLayout>
          <br />
          <Button
            primary
            disabled={!obligData.consumer_country}
            onClick={() =>
              (!currentPlan && !user.is_manager && !user.is_omp) ||
              (currentPlan &&
                !currentPlan.sum &&
                !currentPlan.sum_sales_tax &&
                !user.is_manager &&
                !user.is_omp)
                ? this.handleActionDialogsOpen("subs")
                : this.doReceiveObligations()
            }
          >
            {t("reports.buttons.vatObl")}
          </Button>
        </Card>
        <br />
        <br />
      </Layout.AnnotatedSection>
    );
  }

  renderDac7ReportsTable = ({ is_immovable } = { is_immovable: false }) => {
    const { dac7Reports, dac7ReportsImmovable, t } = this.props;

    const handleDownloadClick = (file) => {
      this.downloadFile(file);
    };
    const handleSubmitClick = (id) => {
      this.props.getDac7Preview(id).then(() => {
        this.handleActionDialogsOpen("dac7Submit", { id, is_immovable });
      });
    };
    const handlePreviewClick = (id) => {
      this.props.getDac7Preview(id).then(() => {
        this.handleActionDialogsOpen("dac7Preview", { is_immovable });
      });
    };
    const handleDeleteClick = (data) =>
      this.handleActionDialogsOpen("deleteDac7Report", {
        ...data,
        is_immovable,
      });
    const handleEditStatus = (data) =>
      this.handleActionDialogsOpen("changeDac7Status", {
        ...data,
        is_immovable,
      });

    const columns = [
      {
        property: "business_name",
        header: {
          label: t("dac7.businessName"),
        },
      },
      {
        property: "period_year",
        header: {
          label: t("dac7.periodYear"),
        },
      },
      {
        property: "status",
        header: {
          label: t("dac7.status"),
        },
        cell: {
          formatters: [
            (value) => {
              switch (value) {
                case "submitted":
                  return <Badge status={"success"}>{value}</Badge>;
                case "new":
                  return <Badge status={"info"}>{value}</Badge>;
                case "pending":
                  return <Badge status={"attention"}>{value}</Badge>;
                case "declined":
                  return <Badge status={"warning"}>{value}</Badge>;
                default:
                  return <Badge>{value}</Badge>;
              }
            },
          ],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <Box
                  display="flex"
                  alignItems="end"
                  justifyContent="center"
                  gap="4"
                >
                  {this.props.user.is_manager && (
                    <Tooltip content={"Change status"}>
                      <Button plain onClick={() => handleEditStatus(rowData)}>
                        <img src={editIMG} alt='edit status' />
                      </Button>
                    </Tooltip>
                  )}
                  {rowData.status === "new" && (
                    <Tooltip content={t("vatReturns.submit")}>
                      <Button
                        plain
                        onClick={() => handleSubmitClick(rowData.id)}
                      >
                        <img src={vatSubmitIMG} alt='submit' />
                      </Button>
                    </Tooltip>
                  )}
                  {(rowData.status === "submitted" ||
                    rowData.status === "pending") && (
                    <Tooltip content={t("vatReturns.view")}>
                      <Button
                        plain
                        onClick={() => handlePreviewClick(rowData.id)}
                      >
                        <img src={eyeIMG} alt='view' />
                      </Button>
                    </Tooltip>
                  )}
                  {rowData.xml_file && (
                    <Tooltip content={t("vatReturns.downloadFile")}>
                      <Button
                        plain
                        onClick={() => handleDownloadClick(rowData.xml_file)}
                      >
                        <img src={downloadIMG} alt='clip' />
                      </Button>
                    </Tooltip>
                  )}
                  {rowData.status === "new" && (
                    <Tooltip content={t("vatReturns.delete")}>
                      <Button plain onClick={() => handleDeleteClick(rowData)}>
                        <img src={deleteIMG} alt='delete' />
                      </Button>
                    </Tooltip>
                  )}
                </Box>
              );
            },
          ],
        },
      },
    ];

    const reports = is_immovable ? dac7ReportsImmovable : dac7Reports;
    const formattedRows = formatRow(reports, columns);

    return (
      !_.isEmpty(reports) && (
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "number"]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={formattedRows}
            hideScrollIndicator
            hoverable
          />
        </Card>
      )
    );
  }

  renderDac7Section() {
    // const { dac7, dac7Loading } = this.state;
    // const { creatingDac7Report, t } = this.props;
    // const { businessName, period } = dac7;
    // const fillButtonDisabled = [businessName, period].some(
    //   (val) => !Boolean(val)
    // );
    //
    // const handleFillClick = () => {
    //   this.setState({
    //     dac7Loading: true,
    //   });
    //   this.props
    //     .createDac7Report({
    //       business_name: businessName,
    //       period: +period,
    //     })
    //     .then(() => {
    //       this.props.navigate("/dac7-reporting");
    //       this.setState({
    //         dac7Loading: false,
    //       });
    //     })
    //     .catch(({ error }) => {
    //       this.handleActionDialogsOpen("subs", { error });
    //     });
    // };

    return (
      <Dac7Reporting
        renderDac7ReportsTable={this.renderDac7ReportsTable}
        handleActionDialogsOpen={this.handleActionDialogsOpen}
      />
    // { is_immovable: true }
      // <Layout.AnnotatedSection
      //   title={
      //     <Text variant='headingLg' as='p'>
      //       {t("dac7.dac7Reporting")}
      //     </Text>
      //   }
      // >
      //   {this.renderDac7ReportsTable()}
      //   <Card sectioned>
      //     <FormLayout>
      //       <FormLayout.Group>
      //         <TextField
      //           label={t("dac7.businessName")}
      //           value={businessName}
      //           onChange={(value) =>
      //             this.setState((prevState) => ({
      //               dac7: { ...prevState.dac7, businessName: value },
      //             }))
      //           }
      //         />
      //         <Select
      //           label={t("dac7.reportingPeriod")}
      //           options={years}
      //           onChange={(period) => {
      //             this.setState((prevState) => ({
      //               dac7: { ...prevState.dac7, period },
      //             }));
      //           }}
      //           value={period}
      //         />
      //         <SearchingCountry
      //           title={`${t("reports.country")}:`}
      //           placeholder={'All'}
      //           onChange={(country) => {
      //             const newData = { ...this.state.data };
      //             newData.consumer_country = country;
      //             this.setState({ data: newData });
      //           }}
      //           dataCountriesList={[
      //             { label: "All", value: "all" },
      //             ...this.getAllCountriesForSelect(),
      //           ]}
      //           // changeCountry={data.consumer_country}
      //         />
      //       </FormLayout.Group>
      //     </FormLayout>
      //     <br />
      //     <Button
      //       primary
      //       onClick={handleFillClick}
      //       disabled={fillButtonDisabled}
      //       loading={creatingDac7Report && dac7Loading}
      //     >
      //       {t("dac7.fillDac7Report")}
      //     </Button>
      //   </Card>
      // </Layout.AnnotatedSection>
    );
  }

  // renderDac7ImmovablePropertySection() {
  //   const { dac7ImmovableProperty, dac7ImmovableLoading } = this.state;
  //   const { t, creatingDac7Report } = this.props;
  //   const { businessName, period } = dac7ImmovableProperty;
  //   const fillButtonDisabled = [businessName, period].some(
  //     (val) => !Boolean(val)
  //   );
  //
  //   const handleFillClick = () => {
  //     this.setState({
  //       dac7ImmovableLoading: true,
  //     });
  //     this.props
  //       .createDac7Report({
  //         business_name: businessName,
  //         period: +period,
  //         is_immovable: true,
  //       })
  //
  //       .then(() => {
  //         this.props.navigate("/dac7-immovable-property");
  //       })
  //       .catch((err) => {
  //         this.handleActionDialogsOpen("subs");
  //       })
  //       .finally(() => {
  //         this.setState({
  //           dac7ImmovableLoading: false,
  //         });
  //       });
  //   };
  //
  //   return (
  //     <Layout.AnnotatedSection
  //       title={
  //         <Text variant='headingLg' as='p'>
  //           DAC7 immovable property reporting
  //         </Text>
  //       }
  //     >
  //       {this.renderDac7ReportsTable({ is_immovable: true })}
  //       <Card sectioned>
  //         <FormLayout>
  //           <FormLayout.Group>
  //             <TextField
  //               label={t("dac7.businessName")}
  //               value={businessName}
  //               onChange={(value) =>
  //                 this.setState((prevState) => ({
  //                   dac7ImmovableProperty: {
  //                     ...prevState.dac7ImmovableProperty,
  //                     businessName: value,
  //                   },
  //                 }))
  //               }
  //             />
  //             <Select
  //               label={t("dac7.reportingPeriod")}
  //               options={years}
  //               onChange={(period) => {
  //                 this.setState((prevState) => ({
  //                   dac7ImmovableProperty: {
  //                     ...prevState.dac7ImmovableProperty,
  //                     period,
  //                   },
  //                 }));
  //               }}
  //               value={period}
  //             />
  //           </FormLayout.Group>
  //         </FormLayout>
  //         <br />
  //         <Button
  //           primary
  //           onClick={handleFillClick}
  //           disabled={fillButtonDisabled}
  //           loading={creatingDac7Report && dac7ImmovableLoading}
  //         >
  //           Fill DAC7 immovable property report
  //         </Button>
  //       </Card>
  //     </Layout.AnnotatedSection>
  //   );
  // }

  render1099Section() {
    const { report1099 } = this.state;
    const { t } = this.props;
    const { businessName, period } = report1099;
    const fillButtonDisabled = [businessName, period].some(
      (val) => !Boolean(val)
    );

    const handleFillClick = () => {
      this.props
        .create1099Report({
          business_name: businessName,
          period: Number(period),
        })
        .then(() => {
          this.props.navigate("/return-1099");
        });
    };

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            1099 Return
          </Text>
        }
      >
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label={t("dac7.businessName")}
                value={businessName}
                onChange={(value) =>
                  this.setState((prevState) => ({
                    report1099: {
                      ...prevState.report1099,
                      businessName: value,
                    },
                  }))
                }
              />
              <Select
                label={t("dac7.reportingPeriod")}
                options={years}
                onChange={(period) => {
                  this.setState((prevState) => ({
                    report1099: { ...prevState.report1099, period },
                  }));
                }}
                value={period}
              />
            </FormLayout.Group>
          </FormLayout>
          <br />
          <Button
            primary
            onClick={handleFillClick}
            disabled={fillButtonDisabled}
            // loading={this.props.creatingDac7Report}
          >
            Fill 1099 return
          </Button>
        </Card>
      </Layout.AnnotatedSection>
    );
  }

  renderLiabilitiesReport() {
    const { pagination, liabilities, liabData } = this.state;
    const rows = liabilities ? liabilities.items : [];
    const { countries, t } = this.props;

    let countryName = "";
    countries.forEach((country) => {
      if (country.code === liabData.consumer_country) {
        countryName = country.name;
      }
    });

    const columns = [
      {
        property: "vat_number",
        header: {
          label: t("reports.vatNumber"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "type",
        header: {
          label: t("reports.vatType"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "taxPeriod",
        header: {
          label: t("reports.taxPeriod"),
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span>
                {formatDate(rowData.taxPeriod.from)} -{" "}
                {formatDate(rowData.taxPeriod.to)}
              </span>
            ),
          ],
        },
      },
      {
        property: "due",
        header: {
          label: t("reports.dueDate"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "originalAmount",
        header: {
          label: t("reports.origAmount"), // only UK
          transforms: [this.state.sortable],
        },
      },
      {
        property: "outstandingAmount",
        header: {
          label: t("reports.outAmount"), // only UK
          transforms: [this.state.sortable],
        },
      },
    ];
    const sortedRows = formatRow(rows, columns);
    const paginatedRows = paginate(pagination)(sortedRows);

    const bannerInfo = this.getBannerInfo({
      status: liabilities.status,
      country: countryName,
      from_date: liabData.from_date,
      to_date: liabData.to_date,
    });

    return (
      <Page
        fullWidth
        subtitle={
          <Text variant="bodyMd" color="critical">
            {countryName} {t("reports.forThePeriod")}{" "}
            {formatDate(liabData.from_date)} {t("reports.tillLC")}{" "}
            {formatDate(liabData.to_date)}
          </Text>
        }
        title={
          <BackArrowWithComponent
            onClick={() =>
              this.setState({ liabilities: false, obligations: false })
            }
            withComponent={
              <Text variant='heading3xl' as='span'>
                {t("reports.vlReport")}
              </Text>
            }
          />
        }
      >
        <Layout>
          <Layout.Section>
            <br />
            {liabilities.status !== "ready" && (
              <div>
                <Banner title={bannerInfo.title} status={bannerInfo.status}>
                  {bannerInfo.text}
                </Banner>
                <br />
              </div>
            )}
            {!_.isEmpty(paginatedRows.rows) && (
              <DataTable
                columnContentTypes={[
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                ]}
                headings={columns.map(({ header }) => (
                  <Text fontWeight='semibold'>{header.label}</Text>
                ))}
                rows={paginatedRows.rows}
                hideScrollIndicator
              />
            )}
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  renderObligationsReport() {
    const { pagination, obligations, obligData } = this.state;
    const rows = obligations ? obligations.items : [];
    const { countries, t } = this.props;

    let countryName = "";
    countries.forEach((country) => {
      if (country.code === obligData.consumer_country) {
        countryName = country.name;
      }
    });

    const columns = [
      {
        property: "vat_number",
        header: {
          label: t("reports.vatNumber"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "status",
        header: {
          label: t("reports.status"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "periodKey",
        header: {
          label: t("reports.periodKey"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "period",
        header: {
          label: t("reports.period"),
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span>
                {rowData.period} {rowData.period_type} {rowData.year}
              </span>
            ),
          ],
        },
      },
      {
        property: "due",
        header: {
          label: t("reports.dueDate"),
          transforms: [this.state.sortable],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);
    const paginatedRows = paginate(pagination)(sortedRows);

    const bannerInfo = this.getBannerInfo({
      status: obligations.status,
      country: countryName,
      from_date: obligData.from_date,
      to_date: obligData.to_date,
    });

    return (
      <Page
        fullWidth
        subtitle={
          <Text variant="bodyMd" color="critical">
            {countryName} {t("reports.forThePeriod")}{" "}
            {formatDate(obligData.from_date)} {t("reports.tillLC")}{" "}
            {formatDate(obligData.to_date)}
          </Text>
        }
        title={
          <BackArrowWithComponent
            onClick={() =>
              this.setState({ liabilities: false, obligations: false })
            }
            withComponent={
              <Text variant='heading3xl' as='span'>
                {t("reports.futureVatObl")}
              </Text>
            }
          />
        }
      >
        <Layout>
          <Layout.Section>
            <br />
            {obligations.status !== "ready" && (
              <div>
                <Banner title={bannerInfo.title} status={bannerInfo.status}>
                  {bannerInfo.text}
                </Banner>
                <br />
              </div>
            )}
            {!_.isEmpty(paginatedRows.rows) && (
              <DataTable
                columnContentTypes={["text", "text", "text", "text", "text"]}
                headings={columns.map(({ header }) => (
                  <Text fontWeight='semibold'>{header.label}</Text>
                ))}
                rows={paginatedRows.rows}
                hideScrollIndicator
              />
            )}
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  render() {
    const { liabilities, obligations } = this.state;
    const { user, t } = this.props;

    return (
      <div>
        {!liabilities && !obligations && (
          <Page
            title={
              <Text variant='heading3xl' as='span'>
                {t("reports.reports")}
              </Text>
            }
            subtitle={
              <Text variant='bodyLg' as='span' color='subdued'>
                {t("reports.titles.taxAuditDesc")}
              </Text>
            }
            fullWidth
          >
            <PageHelmet title={"Reports"} />

            <Layout>
              {!liabilities && !obligations && this.renderTaxAuditSection()}
              {!liabilities &&
                !obligations &&
                this.renderVatLiabilitiesSection()}
              {!liabilities &&
                !obligations &&
                this.renderVatObligationsSection()}
              {user.is_omp && user.dac7 && this.renderDac7Section()}
              {/*{user.is_omp &&*/}
              {/*  user.dac7 &&*/}
              {/*  this.renderDac7ImmovablePropertySection()}*/}

              {/* {this.render1099Section()} */}
            </Layout>
            <br />
            <br />
          </Page>
        )}

        {liabilities && this.renderLiabilitiesReport()}
        {obligations && this.renderObligationsReport()}

        {this.renderHMRCDialog()}
        {this.renderDeleteReportDialog()}
        {this.renderSubscriptionDialog()}
        {this.renderDeleteDac7ReportDialog()}
        {this.renderChangeDac7StatusDialog()}
        {this.renderDac7Preview()}
        {this.renderDac7Submit()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  countries: state.other.countries,
  defaultLanguage: state.user.defaultLanguage,
  listReports: state.transactions.listReports,
  creatingAuditRep: state.transactions.creatingAuditRep,
  taxAuditMessages: state.messenger.taxAuditMessages,
  reportTypes: state.transactions.reportTypes,
  currentPlan: state.subscription.currentPlan,
  creatingDac7Report: state.declarations.creatingDac7Report,
  dac7Reports: state.declarations.dac7Reports,
  dac7ReportsImmovable: state.declarations.dac7ReportsImmovable,
  deletingDac7Report: state.declarations.deletingDac7Report,
  deletingDac7ReportError: state.declarations.deletingDac7ReportError,
  editingDac7StatusError: state.declarations.editingDac7StatusError,
  editingDac7Status: state.declarations.editingDac7Status,
  dac7Preview: state.declarations.dac7Preview,
  submittingDac7Report: state.declarations.submittingDac7Report,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchNewMessagesCount: () => dispatch(fetchNewMessagesCount()),
    fetchTaxAuditMassages: () => dispatch(fetchTaxAuditMassages()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCompanyDefaultLanguage: () => dispatch(fetchCompanyDefaultLanguage()),
    fetchTransactionsAuditListReports: () =>
      dispatch(fetchTransactionsAuditListReports()),
    fetchReportTypes: () => dispatch(fetchReportTypes()),
    getLiabilities: (params) => dispatch(getLiabilities(params)),
    getObligations: (params) => dispatch(getObligations(params)),
    deleteTaxAuditReport: (params) => dispatch(deleteTaxAuditReport(params)),
    createAuditReport: (params) => dispatch(createAuditReport(params)),
    resetSystemMessage: (params) => dispatch(resetSystemMessage(params)),
    createDac7Report: (params) => dispatch(createDac7Report(params)),
    create1099Report: (params) => dispatch(create1099Report(params)),
    fetchDac7Reports: (params) => dispatch(fetchDac7Reports(params)),
    deleteDac7Report: (id) => dispatch(deleteDac7Report(id)),
    changeDac7Status: (params) => dispatch(changeDac7Status(params)),
    getDac7Preview: (id) => dispatch(getDac7Preview(id)),
    submitDac7Report: (params) => dispatch(submitDac7Report(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Reports))
);
