import React from "react";
import ArrowCircleLeftIMG from "img/arrow-circle-left.svg";
import { Box, Button} from "@shopify/polaris";
import "./index.scss";

const BackArrowWithComponent = ({ withComponent, onClick }) => {
  return (
    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
      <Button
        plain
        onClick={onClick}
        icon={
          <img
            src={ArrowCircleLeftIMG}
            alt='arrow back'
            className='arrow-back-fixer'
            style={{ marginRight: 10 }}
          />
        }
      />
      {withComponent}
    </div>
  );
};

export default BackArrowWithComponent;
